html.dark-theme .switcher-wrapper {
	 background: #12181a;
}
html.dark-theme body {
	color: #e4e5e6;
	background-color: #070d0e
}

html.dark-theme a {
	color: #fff;
    text-decoration: none;
}

html.dark-theme .w_percent {
    color: #d7dde5;
}

html.dark-theme .text-option{
	color: #ffffff!important
 }
html.dark-theme .h1, html.dark-theme .h2, html.dark-theme .h3, html.dark-theme .h4, html.dark-theme .h5, html.dark-theme .h6, html.dark-theme h1, html.dark-theme h2, html.dark-theme h3, html.dark-theme h4, html.dark-theme h5, html.dark-theme h6 {
	color: #e4e5e6
}
html.dark-theme .card {
	background-color: #12181a;
	box-shadow:0 0.1rem 0.7rem rgb(0 0 0 / 20%)
}

html.dark-theme .chat-list .list-group-item.active {
    background-color: #3e4348;
}

html.dark-theme .chat-tab-menu .nav-pills .nav-link.active,
html.dark-theme .chat-tab-menu .nav-pills .show>.nav-link {
    color: #ffffff;
    background-color: rgb(0 123 255 / 0%);
}

html.dark-theme .row.row-group>div {
    border-right: 1px solid rgb(255 255 255 / 12%);
}

html.dark-theme .card-header {
	border-bottom: 1px solid rgb(255 255 255 / 13%)
}
html.dark-theme .card-footer {
	border-top: 1px solid rgb(255 255 255 / 13%)
}
html.dark-theme .text-secondary {
	color: #a8adb0!important
}

html.dark-theme .topbar {
	background-color: #12181a;
	border-bottom: 1px solid rgb(237 237 237 / 12%);
	box-shadow: 0 0.1rem 0.7rem rgb(0 0 0 / 20%)
}
html.dark-theme .user-box {
	border-left: 1px solid rgb(255 255 255 / .15);
    border-right: 1px solid rgb(255 255 255 / .15);
}
html.dark-theme .user-info .user-name {
	color: #e4e5e6
}
html.dark-theme .user-info .designattion {
	color: #b3b3b3
}
html.dark-theme .product-list .row {
	background-color: rgb(255 255 255 / 8%)
}
html.dark-theme .product-img {
	background-color: rgb(0 0 0 / 25%);
	border: 1px solid rgb(255 255 255 / 15%)
}
@media (min-width:992px) {
	html.dark-theme .product-list .row:hover {
		background-color: rgb(255 255 255 / 15%);
		margin-top: -.25rem;
		margin-bottom: .25rem;
		-webkit-box-shadow: 0 .5rem 1rem 0 rgba(0, 0, 0, .2);
		box-shadow: 0 .25rem .5rem 0 rgba(0, 0, 0, .2)
	}
}
html.dark-theme .recent-product-img {
	background-color: rgb(255 255 255 / 10%);
	border: 1px solid rgb(255 255 255 / 10%)
}
html.dark-theme .page-footer {
	background-color: #12181a;
	border-top: 1px solid rgb(237 237 237 / 12%)
}
html.dark-theme .mobile-toggle-menu {
	color: #e4e5e6
}
html.dark-theme .btn-close {
	filter: invert(1) grayscale(100%) brightness(200%)
}
html.dark-theme .logo-icon {
	filter: invert(1) grayscale(100%) brightness(200%)
}
html.dark-theme .toggle-icon {
	color: #e7e9ec
}
html.dark-theme .topbar .navbar-nav .nav-link {
	border-left: 0 solid rgb(255 255 255 / 12%);
	color: #e4e5e6
}

html.dark-theme .topbar .navbar .dropdown-app .dropdown-menu .app-name {
	color: #e4e5e6
}

html.dark-theme .topbar .navbar .dropdown-app .dropdown-menu .app-box:hover {
    background-color: #32363a;
}


html.dark-theme .topbar .navbar .dropdown-menu {
	border: 1px solid rgb(241 241 241 / 15%);
}

html.dark-theme .dropdown-large .cart-product {
	border: 1px solid rgb(241 241 241 / 15%);
}


html.dark-theme .dropdown-large .cart-product-title {
	color: #e4e5e6
}

html.dark-theme .dropdown-large .cart-product-cancel {
	color: #e4e5e6;
	border: 1px solid rgb(241 241 241 / 15%);
	background-color: rgb(255 255 255 / 12%);
}


html.dark-theme .topbar .navbar .navbar-nav .nav-link:hover,
html.dark-theme .topbar .navbar .navbar-nav .nav-link:focus {
	background-color: rgb(255 255 255 / 12%);
    color: #ffffff;
}



html.dark-theme .search-bar a.btn,
html.dark-theme .search-bar a.btn:focus{

    color: #e4e5e6;

    background-color: #252a2c;

}


html.dark-theme .search-bar input::placeholder {
	color: #e4e5e6!important;
	opacity: .5!important
}
html.dark-theme::placeholder {
	color: #e4e5e6!important;
	opacity: .5!important
}


html.dark-theme .dropdown-menu {
	color: #e4e5e6;
	background-color: #12181a;
	box-shadow: 0 .5rem 1rem rgb(0 0 0 / .3)
}
html.dark-theme .dropdown-item {
	color: #acafb3
}
html.dark-theme .dropdown-menu .dropdown-item:hover {
	background-color: #303337
}
html.dark-theme .dropdown-divider {
	border-top: 1px solid rgb(255 255 255 / 12%)
}
html.dark-theme .dropdown-large .dropdown-menu .dropdown-item {
	border-bottom: 1px solid rgb(255 255 255 / 12%)
}
html.dark-theme .dropdown-large .msg-name {
	color: #fff
}
html.dark-theme .dropdown-large .msg-info {
	color: #80868c
}
html.dark-theme .dropdown-large .msg-time {
	color: #b1afaf
}
html.dark-theme .dropdown-large .msg-header {
	border-bottom: 1px solid rgb(255 255 255 / 15%);
	background: 0 0
}
html.dark-theme .dropdown-large .msg-header .msg-header-title {
	color: #e4e5e6
}
html.dark-theme .dropdown-large .msg-header .msg-header-clear {
	color: #999ea2
}
html.dark-theme .dropdown-large .msg-footer {
	color: #d1d7de;
	border-top: 1px solid rgb(255 255 255 / .15)
}
html.dark-theme .btn-white {
	color: #e4e5e6;
	background-color: #12181a;
	border-color: rgb(206 212 218 / 45%);
	border-top: 1px solid #63686f;
	border-left: 1px solid #63686f
}
html.dark-theme .user-img {
	border: 1px solid rgb(255 255 255 / 28%)
}
html.dark-theme .customers-contacts a {
	background: rgb(255 255 255 / 12%);
	border: 1px solid rgb(255 255 255 / 14%);
	color: #fff
}
@media (min-width:992px) {
	html.dark-theme .customers-list .customers-list-item:hover {
		background-color: rgb(255 255 255 / 12%)
	}
}
html.dark-theme .order-actions a {
	background: rgb(255 255 255 / 12%);
	border: 1px solid rgb(255 255 255 / 14%);
	color: #fff
}
html.dark-theme .alert {
	background-color: #161515
}
html.dark-theme .accordion-button {
	color: #bac0c6;
	border: 1px solid rgb(255 255 255 / 14%)
}
html.dark-theme .accordion-button:not(.collapsed) {
	background-color: rgb(255 255 255 / 12%)
}
html.dark-theme .nav-tabs .nav-link {
	color: #e3e4e5
}
html.dark-theme .nav-tabs .nav-item.show .nav-link, html.dark-theme .nav-tabs .nav-link.active {
	color: #ffffff;
    background-color: #12181a;
}
html.dark-theme .nav-tabs {
	border-bottom: 1px solid rgb(255 255 255 / 14%)
}
html.dark-theme .nav-primary.nav-tabs .nav-link.active {
	border-color: rgb(255 255 255 / 12%) rgb(255 255 255 / 12%) rgb(255 255 255 / 14%)
}
html.dark-theme .nav-danger.nav-tabs .nav-link.active {
	border-color: #f41127 #f41127 rgb(255 255 255 / .14)
}
html.dark-theme .nav-success.nav-tabs .nav-link.active {
	border-color: #17a00e #17a00e rgb(255 255 255 / .14)
}
html.dark-theme .nav-warning.nav-tabs .nav-link.active {
	border-color: #ffc107 #ffc107 rgb(255 255 255 / .14)
}
html.dark-theme .nav-pills .nav-link {
	color: #e7e8ea
}
html.dark-theme .theme-icons {
	background-color: #12181a
}
html.dark-theme .pricing-table .card ul li.list-group-item {
	color: #e5dede
}
html.dark-theme .table {
	--bs-table-bg: transparent;
	--bs-table-striped-color: #e4e5e6;
	--bs-table-striped-bg: rgba(255, 255, 0255, 0.05);
	--bs-table-active-color: #e4e5e6;
	--bs-table-active-bg: rgba(255, 255, 255, 0.1);
	--bs-table-hover-color: #e4e5e6;
	--bs-table-hover-bg: rgba(255, 255, 255, 0.075);
	width: 100%;
	margin-bottom: 1rem;
	color: #e4e5e6;
	vertical-align: top;
	border-color: rgb(255 255 255 / 12%)
}
html.dark-theme .table-light {
	--bs-table-bg: rgb(255 255 255 / 12%);
	color: #fff
}
html.dark-theme .table td, html.dark-theme .table th {
	border-color: rgb(255 255 255 / 12%)
}
html.dark-theme .table thead th {
	border-bottom: 2px solid rgb(255 255 255 / 12%)
}
html.dark-theme .border {
	border: 1px solid rgb(255 255 255 / 12%)!important
}
html.dark-theme .border-end {
	border-right: 1px solid rgb(255 255 255 / 12%)!important
}
html.dark-theme .border-3 {
	border-width: 3px!important
}
html.dark-theme .topbar .navbar .dropdown-menu::after {
	background: #12181a;
	border-top: 1px solid rgb(241 241 241 / 15%);;
	border-left: 1px solid rgb(241 241 241 / 15%);
}
html.dark-theme .topbar .navbar .dropdown-large .dropdown-menu::after {
	background: #12181a;
	border-top: 1px solid rgb(241 241 241 / 15%);;
	border-left: 1px solid rgb(241 241 241 / 15%);
}




html.dark-theme .highcharts-background {
	fill: rgb(255 255 255 / 0%)
}
html.dark-theme .highcharts-title {
	fill: #e4e5e6!important;
	font-size: 1.5em
}
html.dark-theme .highcharts-root text {
	fill: #8f949a
}
html.dark-theme .highcharts-grid-line {
	fill: none;
	stroke: rgb(255 255 255 / 12%)
}
html.dark-theme .sidebar-wrapper {
	background: #12181a;
	border-right: 1px solid rgb(255 255 255 / 12%);
	box-shadow: 0 0.1rem 0.7rem rgb(0 0 0 / 20%) !important;
}
html.dark-theme .sidebar-wrapper .sidebar-header {
	background: #12181a;
	border-right: 1px solid rgb(255 255 255 / 12%);
	border-bottom: 1px solid rgb(255 255 255 / 12%)
}
html.dark-theme .sidebar-wrapper .sidebar-header .logo-text {
	color: #e7e9ec
}
html.dark-theme .sidebar-wrapper .sidebar-header .toggle-btn {
	color: #e7e9ec
}
html.dark-theme .sidebar-wrapper .sidebar-header .toggle-btn:hover {
	color: #fff
}
html.dark-theme .sidebar-wrapper .sidebar-header .toggle-btn:active {
	color: #fff;
	background: rgb(255 255 255 / 25%)
}
html.dark-theme .sidebar-wrapper .metismenu a {
	color: #9ea4aa
}
html.dark-theme .sidebar-wrapper .metismenu .mm-active>a, html.dark-theme .sidebar-wrapper .metismenu a:active, html.dark-theme .sidebar-wrapper .metismenu a:focus, html.dark-theme .sidebar-wrapper .metismenu a:hover {
	color: #fff;
	text-decoration: none;
	background-color: rgb(255 255 255 / 12%);
}
html.dark-theme .sidebar-wrapper .metismenu ul {
	border: 1px solid #ffffff00;
	background: rgb(255 255 255 / 0%)
}
html.dark-theme .simplebar-scrollbar:before {
	background: rgba(255, 255, 255, .4)
}
html.dark-theme .footer {
	background: #12181a;
	border-top: 1px solid rgb(255 255 255 / 12%)
}
html.dark-theme .btn-light-warning {
	color: #ff9305
}
html.dark-theme .btn-light-dark {
	color: #757c83
}
html.dark-theme .modal-content {
	background-color: #12181a
}
html.dark-theme .modal-header {
	border-bottom: 1px solid rgb(222 226 230 / 16%)
}
html.dark-theme .modal-footer {
	border-top: 1px solid rgb(222 226 230 / 16%)
}

html.dark-theme .email-wrapper {
	background: #12181a;
	box-shadow: 0 .1rem .7rem rgb(0 0 0 / 24%)
}
html.dark-theme .email-sidebar {
	background: #12181a;
	border-right: 1px solid rgb(255 255 255 / .12)
}
html.dark-theme .email-sidebar-header {
	background: #12181a;
	border-bottom: 1px solid rgb(255 255 255 / .12);
	border-right: 0 solid rgb(255 255 255 / 12%)
}
html.dark-theme .email-navigation {
	border-bottom: 1px solid rgb(255 255 255 / 13%)
}
html.dark-theme .email-navigation a.list-group-item {
	color: #e4e5e6;
	background-color: #12181a;
	border-bottom: 1px solid rgb(0 0 0 / 0%)
}
html.dark-theme .email-navigation a.list-group-item:hover {
	background-color: rgb(0 123 255 / 15%)
}
html.dark-theme .email-meeting a.list-group-item {
	color: #e4e5e6;
	background-color: #12181a;
	border-bottom: 1px solid rgb(0 0 0 / 0%)
}
html.dark-theme .email-meeting a.list-group-item:hover {
	background-color: rgb(0 123 255 / 15%)
}
html.dark-theme .list-group-item {
	background-color: #12181a;
	color: #fbfcfd;
	border: 1px solid rgb(255 255 255 / 13%)
}
html.dark-theme .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #008cff;
    border-color: #008cff;
}

html.dark-theme .list-group-item:hover {
	background-color: rgb(255 255 255 / 10%)
}
html.dark-theme .email-navigation a.list-group-item.active {
	color: #fbfcfd;
	font-weight: 600;
	background-color: rgb(13 110 253 / 14%)
}
html.dark-theme .list-group-flush>.list-group-item {
	border-width: 0 0 1px
}
html.dark-theme .border-top {
	border-top: 1px solid rgb(255 255 255 / 13%)!important
}
html.dark-theme .email-header {
	background: #12181a;
	border-bottom: 1px solid rgb(255 255 255 / 13%)
}
html.dark-theme .email-content {
	background: #12181a
}
html.dark-theme .email-list div.email-message {
	background: #12181a;
	border-bottom: 1px solid rgb(255 255 255 / 12%);
	color: #e4e5e6
}
html.dark-theme .email-list div.email-message:hover {
	transition: all .2s ease-out;
	background: #434a50
}
html.dark-theme .bg-body {
	background-color: #12181a!important
}

html.dark-theme .email-time {
	font-size: 13px;
	color: #8c969e
}

html.dark-theme .chat-time {
	font-size: 13px;
	color: #8c969e
}
html.dark-theme .widgets-icons {
	color: #12181a
}
html.dark-theme .invoice {
	background-color: #12181a
}

html.dark-theme .chat-wrapper {
	background: #12181a;
	box-shadow: 0 .1rem .7rem rgb(0 0 0 / 22%)
}
html.dark-theme .chat-sidebar {
	background: #12181a;
	border-right: 1px solid rgb(255 255 255 / 13%)
}
html.dark-theme .chat-sidebar-header {
	background: #12181a;
	border-bottom: 1px solid rgb(255 255 255 / 13%);
	border-right: 0 solid rgb(255 255 255 / 13%)
}
html.dark-theme .chat-header {
	background: #12181a;
	border-bottom: 1px solid rgb(255 255 255 / 13%)
}
html.dark-theme .chat-tab-menu li a.nav-link {
	color: #c3c5c8
}

html.dark-theme .product-img-2 {
	background-color: #12181a;
	border: 1px solid rgb(255 255 255 / 14%);
}

html.dark-theme .chat-title {
	color: #e4e5e6
}
html.dark-theme .chat-msg {
	font-size: 14px;
	color: #848992
}
html.dark-theme .chat-top-header-menu a {
	color: #c3c5c8;
	background-color: #12181a;
	border: 1px solid rgb(255 255 255 / 15%)
}
html.dark-theme .chat-content-leftside .chat-left-msg {
	background-color: #12181a
}
html.dark-theme .chat-content-rightside .chat-right-msg {
	background-color: #41484e
}
html.dark-theme .chat-footer {
	background: #12181a;
	border-top: 1px solid rgb(255 255 255 / 13%)
}
html.dark-theme .chat-footer-menu a {
	color: #c3c5c8;
	background-color: #12181a;
	border: 1px solid rgb(255 255 255 / 15%)
}
html.dark-theme .fm-menu .list-group a {
	color: #c3c5c8
}
html.dark-theme .fm-icon-box {
	background: #12181a;
	color: #fff
}
html.dark-theme .contacts-social a {
	background: #12181a;
	color: #fff
}
html.dark-theme .invoice table td, html.dark-theme .invoice table th {
	background: rgb(255 255 255 / 12%);
	border-bottom: 1px solid rgb(255 255 255 / 12%)
}
html.dark-theme .invoice table .no {
	color: #fff;
	background: #007bff
}
html.dark-theme .invoice main .notices {
	background: #12181a
}
html.dark-theme .invoice footer {
	color: #e4e5e6;
	border-top: 1px solid rgb(255 255 255 / 12%)
}

html.dark-theme .fc-theme-standard td, html.dark-theme .fc-theme-standard th {
	border: 1px solid rgb(255 255 255 / .12)
}
html.dark-theme .fc-theme-standard .fc-scrollgrid {
	border: 1px solid rgb(255 255 255 / 12%)
}
html.dark-theme .fc .fc-col-header-cell-cushion {
	color: #fff
}
html.dark-theme .fc .fc-daygrid-day-number {
	color: #fff
}
html.dark-theme .breadcrumb {
	background-color: rgb(33 37 41 / 0%);
}
html.dark-theme .breadcrumb-item.active {
	color: #8b969f
}
html.dark-theme .breadcrumb-title {
	border-right: 1.5px solid rgb(255 255 255 / 18%)
}
html.dark-theme .btn-outline-secondary {
	color: #c5cace;
	border-color: #c2c9ce
}
html.dark-theme .btn-light {
	color: #fff;
	background-color: rgb(255 255 255 / 15%);
	border-color: rgb(255 255 255 / 22%)
}
html.dark-theme .btn-light:hover {
	color: #fff;
	background-color: rgb(255 255 255 / 25%);
	border-color: rgb(255 255 255 / 32%)
}
html.dark-theme .btn-dark {
	color: #fff;
	background-color: #181e22;
	border-color: #181e22
}
html.dark-theme .btn-dark:hover {
	color: #fff;
	background-color: #13181b;
	border-color: #13181b
}
html.dark-theme .btn-outline-dark {
	color: #fff;
	border-color: #181e22
}
html.dark-theme .page-link {
	color: #b2b6ba;
	background-color: #12181a;
	border: 1px solid rgb(255 255 255 / .12)
}
html.dark-theme .page-item.disabled .page-link {
	color: #b0b6bc;
	background-color: rgb(255 255 255 / 28%);
	border-color: rgb(222 226 230 / 14%)
}
html.dark-theme .progress {
	background-color: rgb(255 255 255 / 14%)
}
html.dark-theme .chip {
	color: rgb(198 200 203);
	background-color: #12181a;
	border: 1px solid rgb(255 255 255 / 15%)
}
html.dark-theme .bg-light {
	background-color: rgb(255 255 255 / 15%)!important
}
html.dark-theme ul.icons li {
	box-shadow: 0 1px 5px #13171a;
	background: #12181a
}
html.dark-theme ul.icons li i {
	color: #fff
}
html.dark-theme .icon-box {
	background: #12181a;
	box-shadow: 0 1px 5px #161a1e
}
html.dark-theme .pByfF i {
	color: #fff
}



/* select 2 */

html.dark-theme .select2-container--bootstrap-5 .select2-selection {
	color: #c0c8d1;
    background-color: #12181a;
	border: 1px solid rgb(255 255 255 / 31%);
}

html.dark-theme .select2-container--bootstrap-5 .select2-selection--multiple .select2-selection__rendered .select2-selection__choice {
	color: #c8c8c8;
	border: 1px solid rgb(255 255 255 / 26%);
}


html.dark-theme .select2-container--bootstrap-5 .select2-selection--single .select2-selection__rendered {
	color: #ffffff;
}

html.dark-theme .select2-container--bootstrap-5 .select2-dropdown .select2-search .select2-search__field {
	color: #ffffff;
    background-color: #4c4d53;
	border: 1px solid rgb(255 255 255 / 31%);
}

html.dark-theme .select2-container--bootstrap-5 .select2-dropdown {
	color: #d0d2d3;
}


html.dark-theme .form-control {
	color: #c0c8d1;
	background-color: #12181a;
	border: 1px solid rgb(255 255 255 / 34%)
}
html.dark-theme .form-select {
	color: #c0c8d1;
	background-color: #12181a;
	border: 1px solid rgb(255 255 255 / 34%)
}
html.dark-theme .form-control:focus {
	box-shadow: 0 0 0 .25rem rgba(255, 255, 255, .25)
}
html.dark-theme .form-select:focus {
	box-shadow: 0 0 0 .25rem rgba(255, 255, 255, .25)
}
html.dark-theme .form-control:disabled, html.dark-theme .form-control[readonly] {
	background-color: rgb(255 255 255 / 22%)
}
html.dark-theme .form-control::placeholder {
	color: #e4e5e6!important;
	opacity: .5!important
}
html.dark-theme .picker__input {
	background-color: #12181a!important;
	border: 1px solid rgb(255 255 255 / 34%)!important
}
html.dark-theme .select2-container--bootstrap4 .select2-selection {
	background-color: rgb(255 255 255 / .14);
	border: 1px solid rgb(255 255 255 / .35)
}
html.dark-theme .select2-dropdown {
	background-color: #12181a;
	border: 1px solid rgb(255 255 255 / 38%)
}
html.dark-theme .select2-search--dropdown .select2-search__field {
	border: 1px solid rgb(255 255 255 / 34%);
	background-color: rgb(255 255 255 / 14%);
	color: #e3dfdf
}
html.dark-theme .select2-container--bootstrap4 .select2-selection--multiple .select2-selection__choice {
	color: #dee1e5;
	border: 1px solid rgb(255 255 255 / 55%);
	background: #12181a
}
html.dark-theme .select2-container--bootstrap4 .select2-selection--multiple .select2-selection__rendered .select2-search__field {
	background: 0 0;
	color: #e6e1e1
}
html.dark-theme .select2-container--bootstrap4 .select2-dropdown .select2-results__option[aria-selected=true] {
	background-color: rgb(255 255 255 / 20%)
}
html.dark-theme .ff_fileupload_wrap .ff_fileupload_dropzone {
	background-color: rgb(252 252 252 / 14%);
	border: 2px dashed rgb(255 255 255 / 50%)
}
html.dark-theme .imageuploadify {
	background-color: rgb(255 255 255 / 14%);
	border: 2px dashed rgb(255 255 255 / .5)
}
html.dark-theme .bootstrap-tagsinput {
	background-color: #12181a;
	border: 1px solid rgb(255 255 255 / 35%);
	box-shadow: inset 0 1px 1px rgb(0 0 0 / 37%);
	color: #e5e0e0
}
html.dark-theme .input-group-text {
	color: #d1d7de;
	background-color: #3e464c;
	border: 1px solid rgb(255 255 255 / 35%)
}
html.dark-theme .table-bordered td, html.dark-theme .table-bordered th {
	border: 1px solid rgb(255 255 255 / 12%)
}
html.dark-theme .table-dark {
	background-color: #1d2126
}
html.dark-theme .table .thead-light th {
	background-color: rgb(255 255 255 / 20%);
	border-color: #fff
}
html.dark-theme .table-bordered {
	border: 1px solid rgb(255 255 255 / 12%)
}
html.dark-theme .login-card {
	border-right: 1px solid rgb(255 255 255 / 12%)!important
}
html.dark-theme .border-bottom {
	border-bottom: 1px solid rgb(255 255 255 / 14%)!important
}
html.dark-theme .navbar-light .navbar-nav .nav-link {
	color: rgb(255 255 255 / 50%)
}
html.dark-theme .navbar-light .navbar-nav .active>.nav-link, html.dark-theme .navbar-light .navbar-nav .nav-link.active, html.dark-theme .navbar-light .navbar-nav .nav-link.show, html.dark-theme .navbar-light .navbar-nav .show>.nav-link {
	color: rgb(255 255 255 / 90%)
}
html.dark-theme .bg-light-purple {
	background-color: rgb(130 128 255 / 32%)!important
}
html.dark-theme .bg-light-primary {
	background-color: rgb(103 58 183 / 33%)!important
}
html.dark-theme .bg-light-danger {
	background-color: rgb(220 53 69 / 33%)!important
}
html.dark-theme .bg-light-shineblue {
	background-color: rgb(1 215 255 / 33%)!important
}
html.dark-theme .bg-light-cyne {
	background-color: rgb(0 139 139 / 32%)!important
}
html.dark-theme .bg-light-warning {
	background-color: rgb(255 193 7 / 32%)!important
}
html.dark-theme .bg-light-success {
	background-color: rgb(40 167 69 / 33%)!important
}
html.dark-theme .bg-light-info {
	background-color: rgb(25 143 237 / 22%)!important
}
html.dark-theme .bg-light-sinata {
	background-color: rgb(165 43 187 / 33%)!important
}
html.dark-theme .bg-light-mehandi {
	background: rgb(131 152 56 / 32%)!important
}
html.dark-theme .logo-white {
	display: block
}
html.dark-theme .logo-dark {
	display: none
}
html.dark-theme .email-toggle-btn {
	color: #c9cbce;
	background-color: #ffffff00
}
html.dark-theme .chat-toggle-btn {
	color: #c9cbce;
	background-color: #ffffff00;
	border: 0 solid rgb(0 0 0 / 15%)
}
html.dark-theme .apexcharts-datalabel, html.dark-theme .apexcharts-datalabel-label, html.dark-theme .apexcharts-datalabel-value, html.dark-theme .apexcharts-datalabels, html.dark-theme .apexcharts-pie-label {
	fill: #fff
}
html.dark-theme .product-discount {
	color: #fff;
	background-color: rgb(255 255 255 / 12%)
}
@media screen and (max-width:1024px) {
	html.dark-theme .wrapper.toggled .overlay {
		background: #fff;
		opacity: .4
	}
	html.dark-theme .chat-toggled .overlay {
		background: #fff;
		opacity: .4
	}
	html.dark-theme .email-toggled .overlay {
		background: #fff;
		opacity: .4
	}


}
@media screen and (max-width:767px) {
	html.dark-theme .topbar .navbar-nav .nav-link {
		border-left: 0 solid #ededed
	}
}