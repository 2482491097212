
/*CUSTOM CSS*/
.short_logo_news_line{
    width: 50px;
    height: 50px;
}

.article_side_banner{
    max-height: 150px;
    margin: 12px;
    cursor: pointer;
}

.header_logo img{
    width: 100%;
    max-width: 210px;
}

button.nav-link.active{
    text-decoration: underline!important;
}

.mob_nav_box button.mob_nav_btn{
    display: none;
}

.content-div1 h5{
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* Вказує кількість рядків, які будуть відображені */
    text-overflow: ellipsis;
    font-weight: bold;
    max-width: 96% ;
    font-size: 1.2rem;
}

.content_isTop{
    font-size: 16px;
    background-color: #f7f7f7;
    /*padding: 0.5rem 1rem;*/
    padding: 5px;
    border-radius: 8px;
    color: #0c232e;
    max-width: 160px;
    display: flex;
    align-items: center;
    flex-direction: column;

    position: absolute;
    bottom: 17px;
    z-index: 2;
    left: 100px;
}



.main-mob-menu{
    position: fixed;
    top: 81px;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    background-color: #d42a23!important;
    z-index: 99999999;

}
.main-mob-menu_list{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
}

.main-mob-menu_list .nav-item{
    font-size: 2.4rem;
    text-transform: uppercase;
    color: #f1f1f1;
    text-decoration: none;
    list-style: none;
}


.small-life-divs{
    width: 100%!important;
    border: solid 2px #d8d2d2!important;
    border-radius: 10px;
}
.right-test2{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.article_banner_box_info_description{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.small-life-divs figure > p {
    min-height: 140px;
    margin: 0;
}

.modal_banners_block{
    gap: 20px;
}
.popup_banner_container{
    max-width: 1000px;
    border-radius: 24px;
}
.popup_banner_item{
    flex: 1 0 30%;
}
.popup_banner{
    border: 1px solid #d8d2d2;
    border-radius: 14px;
    display: block;
    color: #010101;
    text-decoration: none;
}
.popup_banner-img{
    background-color: #0c232e;
    display: block;
    width: 100%;
    height: 140px;
    object-fit: cover;
    object-position: center;
    border-radius: 14px 14px 0 0;
}

.popup_banner-title{
    font-size: 16px;
}
.popup_close_btn{
    background-color: #d42a23;
}


@media screen and (max-width: 768px){
    .login_icon {
        display: none;
    }
    .popup_banner_item{
        flex: 1 0 40%;
    }
    .popup_banner-title{
        font-size: 12px;
    }
}
@media all and (max-width: 480px){
    .popup_banner-img{
        height: 80px;
    }
    .mob_nav_box button.mob_nav_btn{
        display: block;
    }
    .header_logo img{
        max-width: 160px;
    }
    .mob_nav_box button.mob_nav_btn{
        width: 40px;
        height: 40px;
        background-color: rgba(25,25,25,0);
        border : none;
        outline: none;
    }
    button.mob_nav_btn svg{
        width: 30px;
        height: 30px;
        color: #f1f1f1!important;
        fill: #f1f1f1!important;
        cursor: pointer;
    }
}

/*STATISTIC BANNERS*/
 td.statistic_banner{
     vertical-align: middle
 }



/*END CUSTOM CSS*/