@charset "utf-8";
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
/* CSS Document */
*{
	padding: 0;
	margin: 0;
}
body{
	/*font-family: 'Baskervville', serif;*/
	font-family: 'Open Sans', sans-serif;
}



.form-control{
	/*font-family: 'Baskervville', serif;*/
	/*font-family: 'Open Sans', sans-serif;*/
}
img{
	max-width:100%;
}
h1, h2, h3, h4, h5, h6{
	/* font-family: 'Montserrat', sans-serif; */
	/*font-family: 'Baskervville', serif;*/
	/*font-family: 'Open Sans', sans-serif;*/
}
header{

	display:inline-block;
	width: 100%;
	background-color: #222222;
}
.top-bar{
	background-color: #222222;
	color: #fff;
	padding: 10px 0;
	border-bottom: solid 1px #2c2c2c;
}
.date-top{
	text-align: center;
}
.top-bar p,
.news-top-links li a{
	font-size: 14px;
	/*/*font-family: 'Libre Baskerville', serif;*/
}
.socal-top-links li,
.news-top-links li{
	margin: 0 10px;
}
.news-top-links li:first-child{
	margin-left: 0;
}
/* 28-06 */
.colm{
	display: none;
}
.crm-new{
	display: none;
}
.video-modal-d4 .modal-body{
   position: relative;
}
.video-modal-d4 .btn-close{
   position: absolute;
   right: 30px;
   top:30px;
   background-color: #fff;
   border-radius: 50%;
   width: 30px;
   height: 30px;
   overflow: hidden;
   opacity: 9;
   z-index: 9;
}
.video-modal-d4  .modal-content{
	background: none !important;
}
.play-pause-btn{
	position: absolute;
	left: 50%;
	top:50%;
	transform: translate(-50%, -50%);
	background: #fff;
	color: #222;
	width: 45px;
	height: 45px;
	cursor: pointer;
	display: grid;
	align-items: center;
}
.py{
	display: block;
	text-align: center;
}
.play-pause-btn span{
	color: #222222;
	
}

.pas{
	display: none;
	width: 100%;
	text-align: center;
}
.main .pas{
	display: block;
}
.main .py{
	display: none;
}
/* 21-06 */
.slide-section .owl-carousel.owl-drag .owl-item{
	width: auto !important;
}
.socal-top-links li a,
.news-top-links li a{
	color: #fff;
	text-decoration: none;
	/*font-family: 'Libre Baskerville', serif;*/
}
.middle-section{
	display: inline-block;
	width: 100%;
}
.g-ads-sec1{
	height: 70px;
	display: inline-block;
	overflow: hidden;
	width: 100%;
}
.g-ads-sec1 img{
	object-fit: cover;
	width: 100%;
	height: 100%;
}

.main-menu{
	display: inline-block;
	width: 100%;
	position: relative;
}
/* .main-menu::before{
	width: 100%;
    height: 12px;
    position: absolute;
    left: 0;
    bottom: -12px;
    z-index: 1;
	background-image: url(../images/download.png);
	content: "";
} */
.main-menu .navbar{
	padding:5px 0;
	background-color: #d42a23;
	border-radius: 0;
}
.weather-divs h5{
	margin-bottom: 0;
	font-weight: 700;
}
.weather-divs h5 span{
	font-size: 14px;
	font-weight: 600;
	text-transform: uppercase;
	margin: 0 5px;
}
.reports-w figure{
	width: 41px;
	display: inline-block;
}
.weather-divs p{
	font-weight: 700;
	margin-bottom: 0;
}
.comon-menu-div{
	text-decoration: none;
}
.comon-menu-div figure{
	height: 170px;
	overflow: hidden;
	margin-bottom: 0;
}
.comon-menu-div figure img{
	object-fit: cover;
	width: 100%;
	height: 100%;
}
.tags1{
	background: #000;
	display: inline-block;
	text-align: center;
	padding:3px 5px;
	font-size: 15px;
	color: #fff;
	margin: 8px 0;
}
.comon-menu-div p{
	font-size: 15px;
	color: #5d5d5d;
}
.meg-inside .owl-theme .owl-nav{
	text-align: left;
	margin-top: 0;
}
.meg-inside .owl-prev:after{
	left: 0;
	margin: 0;
	bottom: 0;
	background: none;
	color: #5d5d5d;
	border: solid 2px #cfcfcf;
	border-radius: 0;
}
.meg-inside .owl-next:after{
	left: 40px;
	margin: 0;
	bottom:0;
	background: none;
	color: #5d5d5d;
	border: solid 2px #cfcfcf;
	border-radius: 0;
}
.main-menu .navbar{
	position: relative;
}
.main-menu .dropdown{
	position: inherit !important;
}
.main-menu .dropdown-menu1{
	min-width:100%;
	margin: auto;
	left: 0;
	right: 0;
	border: none;
	display: block;
    visibility: hidden;
}
.main-menu .nav-link:hover{
	color: #000 !important;
}
.main-menu .nav-link:hover .dropdown-menu1{
    visibility: visible;
}
.comon-sub-heading{
	position: relative;
	background: #222222;
	color: #fff;
	width: 135px;
    padding: 10px;
	font-size: 16px;
	text-align: center;
	/*font-family: 'Libre Baskerville', serif;*/
}
.breadcrumb{
	/*font-family: 'Libre Baskerville', serif;*/
}
.comon-sub-heading:after{
	content: '';
    position: absolute;
    top: 100%;
    left: 10px;
    margin: auto;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7px 7px 0 7px;
	border-color: #222222 transparent transparent transparent;
}
.comon-slider-items{
	display: flex;
	width: 100%;
	height: 400px;
	overflow: hidden;
	position: relative;
}
.comon-slider-items figure{
	display: inline-block;
	width: 100%;
	height: 400px;

}
.comon-slider-items figure img{
	object-fit: cover;
	width: 100%;
	height: 100%;
	transition: all 0.5s;
	transform: scale(1);

}
.comon-slider-items:hover figure img{
	transform: scale(1.1);
}
.comon-slider-items:after{
	bottom: 0;
    content: "";
    display: block;
    height: 140%;
    width: 100%;
    position: absolute;
	z-index: 1;
	background: -webkit-gradient(linear, left top, left bottom, color-stop(40%, rgba(0, 0, 0, 0)), color-stop(100%, rgba(0, 0, 0, 0.9)));
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.9) 100%);
    background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.9) 100%);
    background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.9) 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.9) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#b3000000', GradientType=0);
	-webkit-transition: background-color 0.3s ease;
    -moz-transition: background-color 0.3s ease;
    -o-transition: background-color 0.3s ease;
    transition: background-color 0.3s ease;
}
.comon-slider-items:hover:after{
    height: 100%;
}

.titel-t{
	position: absolute;
	left: 20px;
	width: 90%;
	bottom: 30px;
	z-index: 2;
	color: #fff;
}
.nes-tags{
	background-color: #d42a23;
	padding: 4px;
    text-align: center;
    width: 61px;
    font-size: 16px;
}
.titel-t p{
	font-size: 17px;
	margin-bottom: 10px;
	line-height: 20px;
	margin-top: 15px;
}
.min-slider-st .owl-prev:after{
	top: -55px;
    right: 41px;
	left: inherit !important;
	background: none !important;
	color: #5d5d5d;
    border: solid 2px #cfcfcf;
    border-radius: 0;
}
.min-slider-st .owl-next:after{
	top: -55px;
    right: 0;
	left: inherit !important;
	background: none !important;
	color: #5d5d5d;
    border: solid 2px #cfcfcf;
    border-radius: 0;
}
.shorts-news-d1{
	margin-top: 0;
}
.shorts-news-d1 .comon-slider-items{
	height: 221px;
	margin-bottom:0;
}
.shorts-news-d1 .comon-slider-items p{
	font-size: 14px;
	/*font-family: 'Libre Baskerville', serif;*/
}
.shorts-news-d1 .date-t{
	font-size:15px;
	/*font-family: 'Libre Baskerville', serif;*/
	color: #bbb;
}
.date-t b{
	/*font-family: 'Libre Baskerville', serif;*/
	font-size: 14px;
	color: #fff;
}
.shorts-news-d1 .titel-t {
    position: absolute;
    left: 14px;
    width: 90%;
    bottom: 10px;
    z-index: 2;
    color: #fff;
}
.total-body{
	display: inline-block;
	width: 100%;
}
.populer-sec1-news{
	background: #fff;
	display: inline-block;
	width: 100%;
	height:301px;
	overflow: hidden;
	box-shadow:rgb(0 0 0 / 4%) 3px 4px 0px;
	text-decoration: none;

}
.populer-sec1-news figure{
	position: relative;
	height:188px;
	overflow: hidden;
}
.populer-sec1-news figure img{
	transform: scale(1);
	transition: all 0.5s;
	object-fit: cover;
	width: 100%;
	height: 100%;


}
.populer-sec1-news:hover figure img{
	transform: scale(1.1);
}
.content-div1{
	padding-left: 10px;
	color: #000;
}
.date-t{
	/*font-family: 'Libre Baskerville', serif;*/
	color: #bbb;
}
.date-t b{
	color: #fff;
}
.st-tags{
	background-color: #d42a23;
    font-size: 16px;
    position: absolute;
    padding: 5px;
    bottom: 17px;
    z-index: 2;
    left: 10px;
    color: #fff;
	/*font-family: 'Libre Baskerville', serif;*/
}
.content-div1 h5{
	font-size: 16px;
	/*font-family: 'Libre Baskerville', serif;*/

	line-height: 22px;


	
}
.content-div1 p{
	font-size: 14px;
	color: #999;
	/*font-family: 'Libre Baskerville', serif;*/
}
.content-div1 p b{
	font-size: 15px;
	color: #686868;
}
.comon-tabs1 .nav-link.active, .comon-tabs1 .show>.nav-link{
     color: #222222;
	 background: none !important;
	 font-weight: 600;
}
.comon-tabs1 .nav-link{
     font-size: 15px;
	 color: #222222;
	 /*font-family: 'Libre Baskerville', serif;*/
	 font-weight: 700;
}
.lisfe-style-sec{
	position: relative;
	display: inline-block;
	width: 100%;

}
.big-life-news{
	background: #fff;
	display: inline-block;
	width: 100%;
	height:480px;
	overflow: hidden;
	box-shadow:rgb(0 0 0 / 4%) 3px 4px 0px;
	text-decoration: none;
	text-decoration: none;
	border: solid 1px #f7f7f7;
}
.big-life-news figure{
	height: 263px;
	width: 100%;
	overflow: hidden;
	display: inline-block;
}
.big-life-news figure img{
	object-fit: cover;
	width: 100%;
	height: 100%;
}
.text-sec{
	color: #222;
	padding-right: 10px;
}
.text-sec h6{
	color: #d42a23;
	font-size: 18px;
}
.small-life-divs{
	overflow: hidden;
	box-shadow:rgb(0 0 0 / 4%) 3px 4px 0px;
	text-decoration: none;
	background: #fff;
	padding: 0 15px 0 0 ;
	margin-bottom: 4px;
	border: solid 1px #f7f7f7;
}
.small-life-divs figure{
	width: 100%;
	height: 140px;
	display: inline-block;
	overflow: hidden;
	margin: 0;
	margin-right: 10px;
	max-width: 200px;
}
.small-life-divs figure img{
	object-fit: contain;
	width: 100%;
	height: 100%;
	min-height: 140px;
	max-height: 146px;
}
.small-life-divs .right-test2{
	width: 70%;
}
.goole-ads1{
	display: grid;
	justify-content: flex-end;
}
.right-test2 h5{
	font-size: 1.2rem;
	font-weight: 700;
	margin-bottom: 5px;
	color: #222;
}
.right-test2 h6{
	font-size: 13px;
	color: #d42a23;
	margin-bottom: 5px;
}
.right-test2 p{
	font-size: 13px;
	color: rgba(95, 95, 95, 0.733);
	margin: 0;
	/*font-family: 'Libre Baskerville', serif;*/
}
.text-sec p{
	font-size: 14px;
	margin-bottom: 10px;
	/*font-family: 'Libre Baskerville', serif;*/
}
.text-sec h5{
	font-size: 16px;
    font-weight: 700;
	/*font-family: 'Libre Baskerville', serif;*/

}
.text-sec .para{
	font-size: 15px;
}
.topic ul{
	display: flex;
	 flex-wrap: wrap;
}
.topic ul li{
	margin:7px;
}
.topic ul li a{
	border: solid 1px #c9c9c9;
    color: rgba(95, 95, 95, 0.733);
    border-radius: 20px;
    display: inline-block;
    text-decoration: none;
    height: 40px;
    font-size: 14px;
    padding: 0 15px;
    line-height: 36px;
	transition: all 0.5s;
	/*font-family: 'Libre Baskerville', serif;*/
	font-weight: 700;

}
.toipc-op p{
	/*font-family: 'Libre Baskerville', serif;*/
}
.view-more-btn{
	/*font-family: 'Libre Baskerville', serif;*/
	font-size: 14px;
}
.trading-ads h2{
	/*font-family: 'Libre Baskerville', serif;*/
}
.topic ul li a:hover{
	background: #222;
	color: #fff;
	border: solid 1px #000;
}
.news-com-sec .populer-sec1-news{
	background: none;
	box-shadow: none;
}
.comon-op{
	box-shadow: rgb(0 0 0 / 4%) 0 4px 0px;
	display: inline-block;
	width: 100%;
	height:140px;
}
.user-pics1{
	width: 100px;
	height: 100px;
	overflow: hidden;
	border-radius: 50%;
	display: inline-block;
}

.toipc-op{
	width: 71%;
}
.toipc-op a{
	text-decoration: none;
	text-transform: capitalize;
	font-weight: 700;
	color: #222;
	font-size: 16px;
	/*font-family: 'Libre Baskerville', serif;*/
}
.toipc-op a:hover{
	text-decoration: underline;
}
.user-pics1 img{
	object-fit: cover;
	width: 100%;
	height: 100%;
}
.entenment-s1 h2{
	width: 170px;
}
.listing-page .comon-slider-items{
    height: 464px;
}
.listing-page .comon-slider-items figure{
	height: 464px;
}
.listing-page .shorts-news-d1 .comon-slider-items{
	height: 229px;
}
.les-post{
	border: 0;
	background: #222;
	border-radius: 0;
	color: #fff !important;
}
.breadcrumb-item a{
    text-decoration: none;
	color: #d42a23;

}
.list-all-sec .populer-sec1-news{
   border: none !important;
   box-shadow: none !important;
}
.list-all-sec  .content-div1 h5{
   font-size: 15px;
}
.tags-list h5{
	font-weight:700;
	margin-bottom: 25px;
}
.tags-list li a{
	border: solid 1px #c9c9c9;
    color: rgba(95, 95, 95, 0.733);
    border-radius: 20px;
    display: inline-block;
    text-decoration: none;
    height: 35px;
    font-size: 14px;
    padding: 0 15px;
	font-weight: 700;
    line-height: 32px;
	margin-bottom: 10px;
    transition: all 0.5s;
	/*font-family: 'Libre Baskerville', serif;*/
}
.tags-list li a:hover {
    background: #222;
    color: #fff;
    border: solid 1px #000;
	
}
.listing-details-page .comon-slider-items{
    height: 520px;
}
.listing-details-page .comon-slider-items figure{
	height: 520px;
}
.listing-details-page .comon-slider-items ul li{
	margin: 0 20px 0 0;
	font-size: 15px;
	/*font-family: 'Libre Baskerville', serif;*/
}
.para-details p{
	/*font-family: 'Libre Baskerville', serif;*/
	font-size: 16px;
}
.para-details h2{
	/*font-family: 'Libre Baskerville', serif;*/
}
.para-details figure{
	height: 400px;
	overflow: hidden;
	width: 100%;
}
.para-details figure img{
	object-fit: cover;
	width: 100%;
	height: 100%;

}
.share-divs{
	border-bottom: solid 1px #dfdddd;
	border-top: solid 1px #dfdddd;
	display: inline-block;
	width: 100%;
	padding:20px 0;

}
.public-text{
	color: #d42a23;
	/*font-family: 'Libre Baskerville', serif;*/
}
.share-divs li {
	margin-right: 15px;
}
.share-divs li a{
	display: inline-block;
	color: #fff !important;
	border-radius: 0;
}
.share-divs li a i{
	margin-right: 5px;
}
.face-book{
	background: #516eab;
}
.twitter-btn{
	background: #29c5f6;
}
.pinterst{
	background: #ca212a;
}
.emial{
	background: #222;
}
.now-news{
	display: inline-block;
	width: 100%;

}
.now-news .comon-sub-heading{
    width: 149px;
}
.more-nes1 .comon-slider-items{
	height: 250px !important;
}
.more-nes1 figure{
	height: 250px !important;
}

.more-nes1 .populer-sec1-news{
	height:381px;
	box-shadow: none !important;
}
.more-nes1 .content-div1 h5{
	font-size: 20px;
}
footer{
	display: inline-block;
	width: 100%;
	background: #222 !important;
	padding-bottom: 20px !important;
	padding-top: 40px;
}
footer h4{
	font-weight: 700;
	font-size: 20px;
	width: 100%;
	color: #fff;
	

}
.ft-1{
	padding-bottom: 10px;
	border-bottom: solid 1px #bbb;
}
.sub-btn{
	background: #d42a23;
	color: #fff;
	border-radius: 0;
	width: 100%;
    margin-top: 10px;
}
footer h5{
	font-size: 20px;
	width: 100%;
	color: #fff;
	margin-bottom: 15px;
	border-bottom: solid 1px rgb(54, 54, 54);
	padding-bottom: 10px;

}
.trems-page .comon-part-trm{
	margin: 15px 0;
	display: inline-block;
	width: 100%;

}
.trems-page ul li{
	margin-bottom: 10px;
}
.trems-page ul li i {
    margin-right: 8px;
    font-size: 18px;
    color: #d42a23;
}
footer ul li{
	color: #bbb !important;
	margin-bottom: 5px;
	display: inline-block;
	width: 100%;
	/*font-family: 'Libre Baskerville', serif;*/
	font-size: 14px;
}
footer ul li a{
	font-size: 15px;
	color: #bbb !important;
	text-decoration: none;

	text-transform: capitalize;
	/*font-family: 'Libre Baskerville', serif;*/
}
footer hr{
	background: #bbb;
}
.sc1 a{
	 margin:10px 10px 0 0;
	 text-decoration: none;
}

.right-anl ul li{
	 width: 50%;
}
.right-anl ul li a{
	text-decoration: none;
	margin: 0 8px;
}
.dropdown:hover>.dropdown-menu1{display:block;top:84%;height:inherit;visibility:visible; transform:skewY(-10px);opacity:inherit;transition:top 0.8s ease;}
@media (min-width: 1366px){
	.meg-inside{
      width: 100%;
	  padding:10px 20px;
	  display: inline-block;
	}
}


.main-menu .navbar .navbar-nav li.nav-item:first-child a.nav-link{
	padding-left: 0 !important;
}
.main-menu .nav-link{
  color: #fff !important;
  padding-left: 18px !important;
  padding-right: 18px !important;
  font-size: 15px;
  /*font-family: 'Libre Baskerville', serif;*/
  font-weight: 700;
}
.weather-divs h5{
	color: #fff;
	text-align: left;
}
.weather-divs h5 span{
	display: block;
	margin: 0;
}
.weather-divs p{
	color: #fff;
	font-size: 36px;
	position: relative;
}
.weather-divs p sup{
	font-size: 14px;
	position: absolute;
	top: 6px;
    right: -15px;
}
.live-td-btn{
	background-color: #d42a23;
	color: #fff;
	-webkit-animation: pulsate-bck 1s ease-in-out infinite both;
    animation: pulsate-bck 1s ease-in-out infinite both;
}
.breaking-news-divs{
	border: solid 1px #e9e6e6;
	width: 100%;
	height: 40px;
	align-items: center;
}
.breaking-news-divs .letf{
	background-color: #d42a23;
	color: #fff;
	width: 150px;
	height: 40px;
	display: grid;
	align-content: center;
	justify-content: center;
	transition: all 0.5s;
	
}
.breaking-news-divs .letf h5{
	font-size: 17px;
}
.breaking-news-divs a{
	text-decoration: none;
	color: #090909;
	margin-right: 15px;
}
.breaking-news-divs a i{
	font-size: 8px;
	margin-right: 5px;
	position: relative;
	top:-3px;
}
.breaking-news-divs a:hover{
	color: #d42a23;
}
.slide-section{
	display: inline-block;
	width: 85%;
}

.cat-list{

}
.cat-list h5{
	box-shadow: rgb(0 0 0 / 4%) 3px 4px 0px;
    text-decoration: none;
	padding:10px 15px;
	border: solid 1px #f7f7f7;
	font-weight: 700;
}
.cat-list ul{
    padding: 0 20px;
	margin-top: 25px;
}
.cat-list ul li{
	margin-bottom: 10px;
	border-bottom: solid 1px #edecec;
	padding-bottom: 8px;
}
.cat-list ul li:last-child{
	border-bottom: none;
}
.cat-list ul li a{
	text-decoration: none;
	color: #222;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.min-slider-st .titel-t p{
	font-size: 20px;
	line-height: 30px;
	width: 50%;
	/*font-family: 'Libre Baskerville', serif;*/
	text-shadow: #000 1px 2px 5px;
}
.cat-list ul li a i{
	color: #d42a23;
}
.cat-list ul li a span{
	width: 133px;
	display: inline-block;
	font-weight: 600;
}

.main-listing-1 .nav{
	border-bottom: solid 1px #dfdddd;
	padding-bottom: 5px;
}
.main-listing-1 .nav-pills .nav-link{
	padding: 8px 0;
	color: #222;
}
.main-listing-1 .nav-pills li.nav-item{
	margin-right:25px;
	font-size: 18px;
}
.main-listing-1 .nav-pills .nav-link.active, 
.main-listing-1 .nav-pills .show>.nav-link{
   background: none !important;
   color: #d42a23 !important;
   font-weight: 700;
}
.comon-news-cat{
	box-shadow: rgb(0 0 0 / 4%) 3px 4px 0px;
    text-decoration: none;
	padding:10px 15px;
	border: solid 1px #f7f7f7;
	background: #fff;
	margin-bottom: 25px;
}
.comon-news-cat figure{
	width: 32%;
	height: 170px;
	overflow: hidden;
}
.comon-news-cat figure img{
	object-fit: cover;
	width: 100%;
	height: 100%;
	transform: scale(1);
	transition: all 0.5s;

}
.comon-news-cat:hover figure img{
	transform: scale(1.1);
}
.news-content-sdiv{
	width: 68%;
	padding-left: 15px;
}
.news-content-sdiv h5{
	color: #222;
	font-weight: 600;
}
.news-content-sdiv ul li{
	color: rgb(97, 97, 97);
	font-size: 13px;
	margin-right: 10px;
}
.news-content-sdiv p{
	font-size: 15px;
	color: rgb(97, 97, 97);
}
.search-blogs-n{
	border:solid 1px #e5e5e5;
	width: 31%;
}
.search-blogs-n .form-control{
	border: none;
}
.search-blogs-n .form-control:focus{
	box-shadow: none;
}
.blogs-pages1 .populer-sec1-news{
    height: 451px;
	margin-bottom: 30px;
}
.blogs-pages1 .content-div1{
	padding-right: 15px;
}
.blogs-pages1 .content-div1 .bl-user{
	display: flex;
	align-items: center;
	color: #bbb;
}
.blogs-pages1 h2{
	font-weight: 700;
}
.big-cv{
	width: 100%;
	height: 500px;
}
.video-div .carousel-indicators [data-bs-target]{
    width: 250px;
	height: 130px;
	opacity: 9 !important;
	text-indent: 0;
	line-height: 0;
	padding: 0;
	overflow: hidden;
}
.video-div .carousel-indicators [data-bs-target] figure{
	width: 250px;
	height: 130px;
}
.video-div .carousel-indicators [data-bs-target] figure img{
	object-fit: cover;
	width: 100%;
	height: 100%;
}
.comon-videos-sections h2{
	border-bottom: solid 1px #e9e9e9;
	padding-bottom: 10px;
	/*font-family: 'Libre Baskerville', serif;*/
}
.live-tv-page h6{
	/*font-family: 'Libre Baskerville', serif;*/
}
.content-div1{
	/*font-family: 'Libre Baskerville', serif;*/
}
.playpause {
  
    width:60px;
    height:60px;
	background: #fff;
	box-shadow: #000 1px 5px 8px;
	color: #000;
	border-radius: 50%;
    position:absolute;
    left:0%;
    right:0%;
    top:0%;
    bottom:0%;
    margin:auto;
    background-position: center;
	display: grid;
	text-align: center;
	align-content:center;
	font-size: 25px;
	justify-content: center;
	z-index: 2;
	cursor: pointer;
}

.blogs-pages1 .content-div1 figure{
	width: 40px;
	height: 40px;
	display: grid;
	align-content: center;
	border-radius: 50%;
	overflow: hidden;
	margin-right: 5px !important;
}
.blogs-pages1 .content-div1 h5{
	font-size: 20px;
	line-height: 28px;
}
.video-comon{
	height: 260px;
	overflow: hidden;
	position: relative;
	display: inline-block;
	width: 100%;
	cursor: pointer;
	text-decoration: none;
}
.copy-link{
	background-color: #d42a23;
	background-color: #d42a23;
    width: 70%;
    font-size: 15px;
    color: #fff !important;
}
.modal-footer ul li a{
	color: #fff !important;
	width: 100%;
	border-radius: 0;
}
.comon-photo-slide figure{
	height: 400px;
	overflow: hidden;
	margin-bottom: 0;
}
.comon-photo-slide figure img{
	object-fit: cover;
	width: 100%;
	height: 100%;
}
.video-comon figure{
	height: 150px;
	width: 100%;
	overflow: hidden;
	display: inline-block;
}
.video-comon figure img{
	object-fit: cover;
	width: 100%;
	height: 100%;
}
.video-comon h5{
	font-size: 15px;
	font-weight: 600;
	color: #000;
	padding-left: 10px;
	/*font-family: 'Libre Baskerville', serif;*/
}
.all-video{
	position: relative;
	width: 100%;

}
.all-video .owl-prev:after{
	left: -44px;
	top: 60px;
	right: inherit !important;
}
.all-video .owl-next:after{
	left: inherit !important;
	top: 60px;
	right: -44px;
}
.video-comon p{
	font-size: 13px;
	color: #d42a23;
	padding-left: 10px;
	/*font-family: 'Libre Baskerville', serif;*/
}
.pl-btn{
	position: absolute;
	top:13%;
	left: 50%;
	transform: translateX(-50%);
	color: #fff;
	font-size: 50px;
	/*font-family: 'Libre Baskerville', serif;*/
}
.live-sec{
	display: inline-block;
	width: 100%;
	position: relative;

}
.shrae-live{
	text-align: right;
}
.shrae-live ul li{
	margin-left: 15px;
}
.shrae-live ul li a{
	color: #d42a23;
	font-size: 25px;
}
.live-tv-page{
	display: inline-block;
	padding: 25px 0;
	width: 100%;
}
.live-tv-page .share-divs{
    border: none;
	padding: 0;
	width: 84%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
.trading-right2 h5{
	font-weight: 700;
	/*font-family: 'Libre Baskerville', serif;*/
}
.live-tv-page h2{
	/*font-family: 'Libre Baskerville', serif;*/
}
.trading-right2 ul li{
	border-bottom: solid 1px #efefef;
	padding-bottom: 8px;
	padding-top:8px;
}
.trading-right2 ul li:last-child{
	border-bottom: none !important;
}
.trading-right2 ul li a{
	color: #222;
	font-size: 16px;
	text-decoration: none;
	font-size: 15px;
	/*font-family: 'Libre Baskerville', serif;*/
}
.trading-right2 ul li a span{
	color: #bbb;
	display: block;
}
.live-tv-page .share-divs li{
	margin-left: 15px;
	margin-right: 0;
}
.phots-page .populer-sec1-news{
	height: 333px;
	cursor: pointer;
}
.phots-page .st-tags{
	right: 10px;
	bottom: inherit;
	left: inherit;
	top:10px
}
.phots-page .populer-sec1-news h6{
	font-size: 14px;
	color: #d42a23;
	/*font-family: 'Libre Baskerville', serif;*/
}
.comon-photo-slide h5{
	/*font-family: 'Libre Baskerville', serif;*/
	font-weight: 700;
	text-shadow: #000 1px 2px 8px;
}
.Signin-btn{
	color: #fff !important;
	/*font-family: 'Libre Baskerville', serif;*/
}
.conatct-page h2{
	border-bottom: solid 1px #e1e1e1;
	padding-bottom: 8px;
	
}
.continue-bn {
    background:#d42a23 !important;
    color: #fff !important;
    font-weight: 700;
    width: 100%;
    display: inline-block;
    box-shadow: 0 20px 30px 0 rgb(172 172 172 / 40%);
}
.regster-bn{
	color: #d42a23 !important;
}
.conatct-page .subimt-message{
	background: #222 !important;
}
.conatct-page h5{
	margin-bottom: 5px;
	font-weight: 700;
	/*font-family: 'Libre Baskerville', serif;*/
}
.conatct-page ul li{
	margin-bottom: 8px !important;
	/*font-family: 'Libre Baskerville', serif;*/
}

.load-more-btn{
	background: #222;
	color: #fff !important;
	border-radius: 0;
	transition: all 0.5s;
	display: table;
}
.btn:focus{
	box-shadow: none !important;
}
.load-more-btn:hover{
	background-color: #d42a23;
}
.dropdown-item.active, .dropdown-item:active{
	background: #000 !important;
	color: #fff !important;
}
@-webkit-keyframes pulsate-bck{0%{-webkit-transform:scale(1);transform:scale(1);}
50%{-webkit-transform:scale(0.9);transform:scale(0.9);}
100%{-webkit-transform:scale(1);transform:scale(1);}
}
@keyframes pulsate-bck{0%{-webkit-transform:scale(1);transform:scale(1);}
50%{-webkit-transform:scale(0.9);transform:scale(0.9);}
100%{-webkit-transform:scale(1);transform:scale(1);}
}

/* new css ends */

.top-head{
	position:absolute;
	left:0;
	right:0;
	top:0;
	z-index:3;
	background:#212529;

}
.line-bg{
	position: relative;
    top: 17px;
	filter: drop-shadow(0px 3px 1px rgba(0,0,0,3%));
}
.line-bg img{
	width: 100%;
    
}
.comon-cart-ps{
	padding: 15px 19px 14px;
    margin: 0;
    border-bottom: solid 1px #ebebeb;
    width: 100%;
    display: inline-block;
}
.cat-count{
	position: absolute;
	bottom:2px;
	display: grid;
	width: 20px;
    height: 20px;
    background: #a0c312;
    color: #fff;
    font-size: 12px;
    align-content: center;
    border-radius: 50%;
    overflow: hidden;
    left: 20px;
    font-weight: 700;
	text-align: center;
	pointer-events: none;
}
.cart-bn{
	background-image: linear-gradient(to right, #9cc026, #5faf21);
	width: 50px;
	height: 50px;
	overflow: hidden;
	border-radius: 50%;
	margin: auto;
	display: grid;
	align-content: center;
	color: #fff;
	position: absolute;
	z-index: 2;
	left: 0;
	right: 0;
	top:150%;
	transition: all 0.5s;
	font-size: 22px;
}
.cart-dropdown-ne{
	width: 310px;
	right: 0;
	left: inherit !important;
	border: none;
	visibility: hidden;
    transition: all 0.5s;
	top:120%;
	display: block !important;
}
.cart-dropdown-ne.show{
	visibility: visible;
	top:100%;
	transition: 0.5s;
	top:100%;
}
.sub-total-products{
	padding:10px 15px;
}
.sub-total-products h4{
    color: #a0c312;
	font-weight: 600;	
}

.titel-crt-products{
	color: #212529;
	text-decoration: none;
	font-size: 14px;
	font-weight: 600;
}
.cart-ps-details h6{
    color: #a0c312;
	font-weight:700;
	margin-top: 5px;	
}
.close-crt{
	color:#212529;
}
.products-sm-pic figure{
	height: 60px;
	width: 60px;
	overflow: hidden;
	margin: 0;
}
.products-sm-pic figure img{
	object-fit: cover;
	width: 100%;
	height: 100%;
}
.navbar-brand{
	filter: drop-shadow(drop-shadow(0px 11px 0px #000));
	position:relative;
}
.navbar-brand img{
	position:relative;
	z-index:2;
}

.navbar{
	padding-bottom: 0;
    
    border-radius: 8px;
    padding: 15px;
}
.mn-head .nav-link{
    font-weight: 600;
	color:#fff !important;
	margin: 0 10px;
	font-family: 'Open Sans', sans-serif;
	border-radius: 0;
	transition: all 0.5s;
	border-bottom: solid 2px transparent;
	text-transform: uppercase;
	font-size: 15px;
}

.com-link{
	color:#fff;
	font-size: 18px;
	text-transform: capitalize;
}
.com-link:nth-child(2){
	font-size: 28px;
}
.com-link:nth-child(3){
	background:#a2be2d;
	color: #fff;
	border-radius: 50%;
}
.mn-head  .navbar-nav .active{
	color: #fff !important;
    border-bottom:solid 2px #a2be2d;
   
}
.mn-head .nav-link:hover{
	border-bottom:solid 2px #a2be2d;
}
.navbar-nav .dropdown-item{
	font-size: 14px;
}
.navbar-nav .dropdown-item:hover{
	color: #fff !important;
    background: #043a64;

}
.cart-new-icon:after{
	display: none;
}
.cart-new-icon:focus{
	box-shadow: none;
}
.cart-new-icon {
	font-size: 25px;
	padding-top: 0;
	padding-bottom: 0;

}













.top-head .nav-link{
	color:#212529 !important;
	font-size:16px;
	transition:all 0.5s;
	padding-left:15px !important;
	padding-right:15px !important;
	font-weight:600;
	
}
.fixed-menu .nav-link{
	color: #000 !important;
}
.sc-logo{
	display: none;
}
.fixed-menu .ds-logo{
	display: none !important;
}
.fixed-menu .sc-logo{
	display: block;
}
.fixed-menu  .navbar-nav .active{
	color: #a2be2d !important;
	background: none;
}
.fixed-menu .com-link{
	color: #000 !important;
}
.fixed-menu .com-link:nth-child(3){
	color: #fff !important;
}
.sec-d-expart .row{
	width:90%;
	margin:auto;
}
.sec-d-expart .row figure{
	border-radius:8px;
	overflow:hidden;
	width:100%;
	height:350px;
}
.sec-d-expart .row figure > img{
	object-fit:cover;
	width:100%;
	height:100%;
}
.sec-d-expart .row h2{
	text-transform:capitalize;
	font-weight: 700;
}
.top-head .nav-link:hover{
	color:#b2b74a !important;
	
}
.service-block h2{
	font-size: 35px;
	font-weight: 700;
}
.service-block h6{
	color: #00579a;
}
.comon-services{
	text-decoration: none;
	box-shadow:5px 7px 15px 2px rgb(82 90 101 / 12%);
	background: #fff;
	width: 100%;
	height: 300px;
	display: grid;
	align-content: center;
	padding: 0 30px;
	transition: all 0.5s;
}
.comon-services figure{
	width: 65px;
    margin: auto;
	margin-bottom: 10px;
	height: 80px;
	overflow: hidden;
	transition: all 0.5s;
}
.hover-show{
	top:-150px;
	position: relative;
	transition: all 0.5s;
}
.dissplay-show{
	position: relative;
	top:0;
	transition: all 0.5s;
}
.comon-services:hover .dissplay-show{
	top:-100px;

}
.comon-services:hover .hover-show{
	top:-58px;
}
.comon-services:hover{

background: #00579a;

}
.Prodcuts-div{
	background: #f1f1f1;
	display: inline-block;
	position: relative;
	width: 100%;
	padding: 50px 0;
}
.bg-img1{
	position: absolute;
	bottom: -10px;
	left: 0;
	right: 0;
}
.bg-img2{
	position: absolute;
	bottom: -260px;
	margin-bottom: 0;
	display: inline-block;
	width: 100%;
	right: 0;
	text-align: right;
}
.Prodcuts-div .container,
.home-about .container{
	position: relative;
	z-index: 2;
}
.comon-services h5,
.comon-services h6,
.Prodcuts-div h6,
.Prodcuts-div h2{
	/* font-family: 'Open Sans', sans-serif; */
	transition: all 0.5s;
}
.comon-services h5{
	color: #161616;
	font-weight: 700;
	font-size: 18px;
	margin-bottom: 8px;
}
.comon-services p{
	color: #737373;
	transition: all 0.5s;
	font-size: 14px;
}
.about-block{
	background: #f6f8fd;
	position: relative;
	width: 100%;
	display: inline-block;
	margin-top: 150px;
}
.about-svg{
	margin-top: -280px;
}
.about-svg-bt{
	margin-top: -230px;
    position: relative;
    top: 251px;
}
.about-block figure{
	height: 500px;
	margin: 0;
	overflow: hidden;
	border-radius: 10px;
}
.about-block figure img{
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.about-block h6,
.why-choose-block h6,
.trust-choose-block h6,
.testimonal-sec h6,
.team-block h6,
.news-sec-div h6,
.client-block h6,
.cm-sub,
.projects-page h6,
.pricing-page  h6{
    color: #8abb25;
}
.team-block .owl-nav{
	position: absolute;
    left: 0;
    right: 0;
    top: 40%;
}
.team-block .owl-prev{
	position: absolute;
	left: -40px;
}
.comon-div-ts span i{
	color: #ffbd01;
}
.team-block .owl-next{
	position: absolute;
	right: -10px;
}
.read-more{
	background:#212529;
	color: #fff;
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
	font-size: 15px;
	margin-top: 20px;
}
.why-choose-block{
	margin-top: 90px;
	position: relative;
	display: inline-block;
	width: 100%;
}

.about-block h2,
.why-choose-block h2,
.trust-choose-block h2,
.testimonal-sec h2,
.team-block h2,
.news-sec-div h2,
.client-block h2,
.blog-page h2,
.projects-page h2,
.pricing-page  h2{
	font-weight: 700;
	text-transform: capitalize;
	line-height: 39px;

}
.our-work-details{
	/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#5e51b0+0,43c7f2+100 */
background: #00579a; /* Old browsers */


display: inline-block;
width: 100%;
color: #fff;
}
.our-work-details .container{
	margin-top: -30px;
}
.our-work-bg1{
	margin-top: -30px;
}
.our-work-details h2{
	font-size: 45px;
	font-weight: 700;
}
.comon-st-div h5{
	font-weight: 700;
	font-size: 30px;
}
.comon-st-div h5 span{
	font-weight: 500;
	font-size: 18px;
}
.skill-info {
	display: block;
	margin-bottom: 20px;
}
.why-choose-block ul,
.trust-choose-block ul{
	column-count: 2;
}
.why-choose-block ul li,
.trust-choose-block ul li{
	box-shadow: 2.5px 4.33px 30px 0 rgb(64 87 191 / 10%);
	padding: 10px 20px;
	background: #fff;
	display: inline-block;
	border-radius: 8px;
	margin-bottom: 18px;
	font-family: 'Open Sans', sans-serif;
	font-weight: 500;
	font-size: 14px;
	text-transform: capitalize;
}
.why-choose-block ul li i,
.trust-choose-block ul li i{
	color: #1c5bea;
	margin-right: 5px;
}
.skill-name {
	float: left;
	color: #272727;
	margin-bottom: 5px;
	font-weight: 600;
	font-family: 'Open Sans', sans-serif;
}

.skill-status {
	float: right;
	color: #00579a;
	font-size: .8em;
	font-weight: 600;
	font-family: 'Open Sans', sans-serif;
}
.skill-bar {
	display: block;
	max-width: 100%;
	width: 100%;
	height: 10px;
	background: #e3e3e5;
	clear: both;
	margin: 20px 0;
}

.skill-level {
	display: table;
	height: 10px;
}
.communication, .planning,
.learning, .programming,
.visual-design, .ux-design {
	/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#5e51b0+0,43c7f2+100 */
background: #00579a; /* Old browsers */


}

.communication {
	width: 40%;
	-webkit-animation: communication 1s linear;
	animation: communication 1s linear;
}

.planning {
	width: 15%;
	-webkit-animation: planning 1s linear;
	animation: planning 1s linear;
}

.learning {
	width: 45%;
	-webkit-animation: learning 1s linear;
	animation: learning 1s linear;
}

.programming {
	width: 30%;
	-webkit-animation: programming 1s linear;
	animation: programming 1s linear;
}

.visual-design {
	width: 20%;
	-webkit-animation: visual-design 1s linear;
	animation: visual-design 1s linear;
}

/*-----------------------
	  	Animations 
-------------------------*/ 
@-webkit-keyframes communication {
	from { width: 0%; }
	to { width: 40%; }
}

@keyframes communication {
	from { width: 0%; }
	to { width: 40%; }
}

/*-----------------------
	Planning Animation 
-------------------------*/
@-webkit-keyframes planning {
	from { width: 0%; }
	to { width: 15%; }
}

@keyframes planning {
	from { width: 0%; }
	to { width: 15%; }
}

/*-----------------------
	Learning Animation 
-------------------------*/ 
@-webkit-keyframes learning {
	from { width: 0%; }
	to { width: 45%; }
}

@keyframes learning {
	from { width: 0%; }
	to { width: 45%; }
}

/*-----------------------
  Programming Animation 
-------------------------*/ 
@-webkit-keyframes programming {
	from { width: 0%; }
	to { width: 30%; }
}

@keyframes programming {
	from { width: 0%; }
	to { width: 30%; }
}

/*-----------------------
Visual-Design Animation  
-------------------------*/ 
@-webkit-keyframes visual-design {
	from { width: 0%; }
	to { width: 20%; }
}

@keyframes visual-design {
	from { width: 0%; }
	to { width: 20%; }
}

/*-----------------------
   UX-Design Animation  
-------------------------*/ 
@-webkit-keyframes ux-design {
	from { width: 0%; }
	to { width: 10%; }
}

@keyframes ux-design {
	from { width: 0%; }
	to { width: 10%; }
}


/*-----------------------
	  Media queries
-------------------------*/ 


@media screen and (max-width: 955px) {
	.skillset {
		padding: 20px;
	}
	.skill-lockup {
		max-width: 700px;
		width: 100%;
		float: none;
		margin: 0 auto;
	}

	.skill-bar {
		max-width: 700px;
		width: 100%;
	}
}


.comon-services:hover h5,
.comon-services:hover h6,
.comon-services:hover p{
	color: #fff;
}
.news-sec-div h1{
	font-weight: 700;
}
.testimonal-sec{
	display: inline-block;
    width: 100%;
    overflow: hidden;
	background: #fff;
}
.testimonal-sec .container{
	position: relative;
	z-index: 2;
}
.testimonal-sec .comon-hed{
	color:#fff;
}
.comon-news-part figure{
	width:100%;
	height:220px;
	overflow:hidden;
	
}
.cont-para li{
	font-size: 14px;
}
.cont-para li i{
	 margin-right: 5px;
}
.comon-news-part figure{
	position:relative;
	margin-bottom: 0;
}
.comon-news-part figure a{
	height: 100%;
	width: 100%;
	display: inline-block;
}
.comon-news-part figure a img{
	object-fit: cover;
	width: 100%;
	height: 100%;
	transform: scale(1);
	transition: all 0.5s;
}
.comon-pic-news{
	height:220px;
	position:relative;
	width:40%;
}
.comon-pic-news p{
	display:inline-block;
	padding:8px 15px;
	background:#b2b74a;
	color:#fff;
	position:absolute;
	bottom:28px;
	left:15px;
}
.call-contact{
	/*background: url(../images/bg-call.jpg) no-repeat;*/
	background-size: cover;
	width: 100%;
	margin-top: -100px;
	padding-bottom: 100px;
}
.call-contact h6,
.call-contact h2,
.call-contact p{
	color: #fff;
}
.client-block{
	padding-top: 30px;
	display: inline-block;
	position: relative;
	width: 100%;
}
.client-logo-cm{
	box-shadow: -1px 5px 20px 0px rgb(82 90 101 / 10%);
	width: 100%;
	display: grid;
	align-content: center;
	height: 114px;
	overflow: hidden;
}
.get-bn{
	background:#fff;
	color: #00579a;
	font-weight: 600;
}
.subcribe-div{
	/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#5e51b0+0,43c7f2+100 */
background: #00579a; /* Old browsers */

    position: relative;
    top: -50px;
    padding: 21px 30px;
	color: #fff;
	border-radius: 8px;
}
.call-contact h2{
	font-weight: 700;
	text-transform: capitalize;
}
.dtails-idv-text p{
	font-size: 15px;
}
.comon-news-part{
	box-shadow: -1px 5px 20px 0px rgb(82 90 101 / 10%);
	width: 100%;
	margin: 25px 0;
	display: flex;
    align-items: center;
    justify-content: space-between;
	padding: 15px 19px;
	flex-wrap: wrap;
	
}
.news-sec-div-part .comon-pic-news,
.news-sec-div-part .dtails-idv-text{
	width: 100%;

}
.news-sec-div-part .dtails-idv-text{
	padding: 14px 0 25px 0;
}
.news-sec-div-part .comon-news-part{
	display: inline-block;
	width: 100%;
	height: 510px;
    position: relative;
}
.dtails-idv-text li{
	margin: 0 10px;
}
.dtails-idv-text li:first-child{
	margin-left: 0;
}
.dtails-idv-text li a{
	text-decoration: none;
	color: #a2be2d;
    font-size: 13px;
    font-weight: 600;
}
.comon-news-part:hover figure img{
	transform: scale(1.2);
	
}
.dtails-idv-text{
	padding:0 25px 25px 25px;
	width: 60%;
	position: relative;
}
.dtails-idv-text h5{
	font-weight:700;
	text-transform: capitalize;
	line-height: 25px;
    font-size: 18px;
}
.dtails-idv-text ul{
	margin-bottom: 5px;
}
.comon-news-part figcaption{
	background:red;
	padding:15px;
	border-radius:7px;
	overflow:hidden;
	text-align:center;
	position:relative;
	z-index:2;
}
.team-block{
	background: #f6f8fd;
    position: relative;
    width: 100%;
    display: inline-block;
    margin-top: 150px;
}
.comon-team-div{
	display: inline-block;
	width: 100%;
	background: #fff;
	box-shadow: 2.5px 4.33px 30px 0 rgb(64 87 191 / 10%);
	padding-bottom: 10px;
}
.comon-team-div figure{
	width: 100%;
	height: 270px;
	overflow: hidden;
}
.comon-team-div figure img{
	object-fit: cover;
	width: 100%;
	height: 100%;
}
.year-text h1{
	/*background: url("../images/p1.jpg");*/
	background-position: bottom;
	-webkit-background-clip:text;
	color: transparent;
	font-size: 201px;
    font-weight: 900;
    background-size: 54%;
    background-repeat: no-repeat;
}
.silder-div-text .item .comon-div-ts{
	width:100%;
	min-height:209px;
	background:#fff;
	box-shadow: 2.5px 4.33px 30px 0 rgb(64 87 191 / 10%);
	border-radius:8px;
	position:relative;
	display: grid;
    align-content: center;
    padding:24px;
	margin-top: 23px;
	text-align: center;
}
.comon-div-ts h5 {
    font-size: 15px;
    line-height: 26px;
}
.year-text p{
	margin-top:-25px;
	font-size:20px;
	text-transform:capitalize;
}
.silder-div-text figure{
	width:50px;
	height:50px;
	overflow:hidden;
	border-radius:50%;
	margin:0;
}
.comon-div-ts{
	display:inline-block;
	width:90%;
	margin:auto;
	padding: 0;
}
.silder-div-text h4{
	font-size:16px;
	font-weight:600;
	margin-left:10px;
}
.silder-div-text .owl-theme .owl-nav.disabled+.owl-dots{
	text-align:left;
}
.silder-div-text h4 span{
	font-weight:300;
	margin-top: 5px;
	font-size: 14px;
}
.silder-div-text .item .comon-div-ts{
	position: relative;
}
.silder-div-text .item .comon-div-ts:before{
	content: "\f10d";
    color: #e7e7e7;
    position: absolute;
    left: 0;
    top: -27px;
	font-family:'FontAwesome';
	font-size: 46px;
	right: 0;

}
.our-work-details i {
    color: #fff;
    font-size: 56px;
    margin-right: 15px;
}
.silder-div-text .item .comon-div-ts:after{
	position: absolute;
	content: "";
	border-left: solid 15px transparent;
    border-right: solid 15px transparent;
    border-top: solid 15px #fff;
    width: 10px;
    height: 10px;
    left: 0;
    bottom: -10px;
	right: 0;
	margin: auto;
}
.silder-div-text .owl-nav{
	position: absolute;
    left: 0;
    right: 0;
    top: 30%;
}
.silder-div-text .owl-nav .owl-prev{
	left: 160px;
	position: absolute;
}
.silder-div-text .owl-next {
	right: 190px;
	position: absolute;
}
.search-bn{
	background:#262b2b;
	color:#fff;
	width:90%;
	text-transform:uppercase;
	height:50px;
}
.banner-div-content .form-select{
	height:50px;
}
.signiup{
	border-radius:50px;
	font-weight:500;
	background:#fff;
	color:#00579a !important;
	padding:7px 20px !important;
	font-size:16px !important;
	display:inline-block;
	border:solid 1px #fff;
	text-decoration: none;
	transition: all 0.5s;
	font-family: 'Poppins', sans-serif;
	-webkit-animation: pulsate-bck 1s ease-in-out infinite both;
    animation: pulsate-bck 1s ease-in-out infinite both;
	cursor: pointer;
}
.signiup:hover{
	background:none;
	color:#fff !important;
	
}
.quick-contact .modal-title{
   font-weight: 'Poppins', sans-serif;
   font-weight: 700;
}
.quick-contact .form-control{
	border: none;
    background: #f1f1f1;
    height: 45px;
	margin-bottom: 15px;
}
.quick-contact textarea.form-control{
	height: 100px !important;
	resize: none;
}
@-webkit-keyframes pulsate-bck{0%{-webkit-transform:scale(1);transform:scale(1);}
50%{-webkit-transform:scale(0.9);transform:scale(0.9);}
100%{-webkit-transform:scale(1);transform:scale(1);}
}
@keyframes pulsate-bck{0%{-webkit-transform:scale(1);transform:scale(1);}
50%{-webkit-transform:scale(0.9);transform:scale(0.9);}
100%{-webkit-transform:scale(1);transform:scale(1);}
}
.fixed-menu .signiup{
	color: #fff !important;
	background: #00579a; /* Old browsers */
	transition: all 0.5s;

}
.cart{
	position:relative;
	margin-left:10px;
}
.cart span i{
	color:#b2b74a;
	font-size:20px;
	
}
.cart small{
	width:20px;
	height:20px;
	border-radius:30px;
	background:#b2b74a;
	display: inline-grid;
    align-content: center;
    text-align: center;
	position: relative;
    right: 10px;
	color:#fff;
	font-size:11px;

}
.banner-part{
	width:100%;
	position: relative;
	top:0;
	/*background-image:url(../images/banner-pic.jpg);*/
	background-repeat:no-repeat;
	background-size:cover;
	height:742px;
	padding-top: 0;
	display: grid;
	align-content: center;
}
.top-bar-sec .row a{
	text-decoration:none;
	color:#fff;
	font-weight:500;
	font-size:15px;
}
.top-bar-sec .row > div{
	margin-top:5px;
	margin-bottom:10px;
	color:#fff;
}
.top-bar-sec .row > div:nth-child(2){
	display:flex;
	flex-wrap: wrap;
    align-items: center;
	justify-content: flex-end;
	text-transform:capitalize;
	font-weight:500;
}
.top-bar-sec .row > div:nth-child(2) a{
	margin:0 7px;
	font-size:15px;
	
}
.fixed-menu{
	background:#fff;
	position: fixed;
	top:0;
	animation:slide-down 0.7s;
	left:0;
	right:0;
    box-shadow:4px -86px 103px rgba(0,0,0,0.9);
	z-index:250;
		
  }

@keyframes slide-down {
		  0% {
			  transform: translateY(-100%);
		  } 
		  100% {
			  transform: translateY(0);
		  } 
	  }
	  
	  @keyframes slide {
		0% {
			opacity: 0;
			transform: translateY(50%);
		} 
		100% {
			opacity: 1;
			transform: translateY(0);
		} 
	}
.banner-div-content{
	animation: fadeInleft 0.5s ease-in-out;
	position: relative;
    z-index: 2;
	text-align:left;
	height:409px;
	}
.banner-div-content h6{
	font-weight: 700;
    font-size:35px;
	/* font-family: 'Open Sans', sans-serif; */
	color: #a2be2d;
	}
.banner-div-content h1{

    font-weight: 900;
    font-size:55px;
	/* font-family: 'Open Sans', sans-serif; */
	/* background-image: url(../images/crystalweed-cannabis-nFumIdf3IHE-unsplash.jpg);
	background-clip: text;
	-webkit-background-clip: text; */
	 color: #fff;
	 text-transform: uppercase;
}
.banner-div-content h1 span{
	text-transform: capitalize;
	font-size: 45px;
}
.no-bn{
	background: red;
	padding: 10px 15px;
	color: #fff;
}

.no-bn,
.yes-bn{
	border: none;
	text-transform: uppercase !important;
	font-weight: 600;
	transition: all 0.5s;
}
.yes-bn:hover,
.cart-drop-bn:hover{
  background: #043a64;
  color: #fff;
}

.check-drop-bn:hover{
	background-image: linear-gradient(to right, #9cc026, #5faf21);
}
.verify-modal .modal-body{
	padding: 50px 0;
	/*background: url(../images/bg.jpg) no-repeat;*/
	border-radius: 0;
}
.verify-modal .modal-dialog{
	max-width: 640px;
}
.verify-modal h2{
	font-weight: 700;
	color: #000 !important;
    text-transform: capitalize;
    font-weight: 800;
    font-size: 34px;
    text-shadow: 1px 1px #fff, -1px 1px #fff, 1px -1px #fff, -1px -1px #fff, 1px 1px 5px #555;
}
.verify-modal p{
	color: #fff;
	font-weight: 600;
}
.consult-bn,
.about-bn,
.view-more,
.submit-bn,
.subcribe,
.view-products,
.ad-cart-bn,
.sub-re,
.edit-bn1,
.checkout-bn,
.form-wizard-next-btn,
.form-wizard-previous-btn,
.form-wizard-submit,
.cart-drop-bn,
.cp-bn,
.expl-bn{
	background-image: linear-gradient(to right, #9cc026, #5faf21);
	color: #fff !important;
	display: inline-block;
	margin: 0;
	margin: 0;
	font-family: 'Open Sans', sans-serif;
    font-size: 15px;
    text-transform: capitalize;
    font-weight: 500;
    margin-top: 16px;
	margin-right: 8px;
	padding: 10px 20px;
	transition: all 0.5s;
	text-decoration: none;
	cursor: pointer;
	
	
}
.yes-bn{
	background: #fff;
	color: #1c2402;
	padding: 10px;
	border-radius: 0;
	margin-right: 8px;
}
.cart-drop-bn{
	width: 90%;
    margin: 0 auto;
    font-weight: 600;
    display: table;
}
.check-drop-bn{
	 background: #043a64;
	 width: 90%;
     margin: 0 auto;
     font-weight: 600;
     display: table;
	 color: #fff;
	 margin-top: 10px;

}
.subcribe{
	border-radius: 0;
}
.comon-our-text{
	background:#fff;
	border-radius: 5px;
	padding: 20px 0;
}
.comon-footer .form-group .form-control{
	border: 0;
}
.contact-quick span > i{
	font-size: 70px;
	color: #fff;
	margin-bottom: 30px;
}
.cp-bn{
	font-weight: 600;
	border-radius: 0;
	margin-top: 0;
	font-size: 13px;
}
.list-linka li{
	margin: 0 8px;
}
.list-linka li a{
   color: #fff;
   text-decoration: none;
}
.comon-our-text figure{
	width: 59px;
	display: table;
	margin: auto;
	margin-bottom: 10px;
}
.comon-our-text h5{
	font-family: 'Red Hat Display', sans-serif;
	font-weight:700;
	font-size: 18px;
    line-height: 25px;
	text-transform: capitalize;
	color: #072327;
}
.offer-div-sec{
	 /*background: url(../images/bg-back-img.jpg) no-repeat;*/
	 background-size: cover;
	 width: 100%;
	 height: 446px;
	 display: grid;
	 align-content: center;
}
.about-bn,
.view-more,
.view-products,
.ad-cart-bn,
.sub-re,
.edit-bn1,
.checkout-bn,
.form-wizard-next-btn,
.form-wizard-previous-btn,
.expl-bn{
	box-shadow: 0 20px 30px 0 rgb(172 172 172 / 40%);
}
.edit-bn1,
.checkout-bn,
.form-wizard-next-btn,
.form-wizard-previous-btn,
.form-wizard-submit,
.expl-bn{
	font-weight: 600;
}
.about-bn:hover,
.view-more:hover,
.view-products:hover,
.ad-cart-bn:hover,
.sub-re:hover,
.edit-bn1:hover,
.checkout-bn:hover,
.form-wizard-next-btn:hover,
.form-wizard-previous-btn:hover,
.form-wizard-submit:hover,
.expl-bn:hover,
.continue-bn{
	box-shadow: 0 20px 30px 0 rgb(172 172 172 / 65%);
}
.ceck-out-right-div hr,
.ad-fm hr{
	background: #bbb;
	
}
.regster-bn{
	text-decoration: none;
	font-weight: 600;
	color: #9cc026;
	cursor: pointer;
}
.sub-re{
	width: auto;
	display: inline-block;
	font-weight: 600;
	margin-left: 10px;
}
.form-wizard-next-btn,
.form-wizard-previous-btn,
.form-wizard-submit{
	border-radius: 5px;
}
.consult-bn:hover{
	background: none !important;
	color: #fff !important;
	
}
.home-about h6{
	color: #a2be2d;
	
}
.home-about h6,
.home-about h2,
.Prodcuts-div h6,
.Prodcuts-div h2,
.offer-div-sec h2{
	/* font-family: 'Open Sans', sans-serif; */
	text-transform: capitalize;
}
.home-about h2,
.Prodcuts-div h2,
.choose-bread h2{
	font-weight: 700;
	color: #0c232e;
}
.offer-div-sec h2{
	color: #fff;
	font-weight: 700;
}
.Prodcuts-div h6,
.choose-bread h6,
.choose-bread h6,
.testimonal-sec h6,
.about-block h6,
.news-sec-div h6,
.top-cm-heade{
	color: #a2be2d;
	display:flex;
	justify-content: center;
	align-items: center;
	/* font-family: 'Open Sans', sans-serif; */
}
.Prodcuts-div h6 span,
.choose-bread h6 span,
.testimonal-sec h6 span,
.about-block h6 span,
.news-sec-div h6 span,
.top-cm-heade span{
	width: 55px;
    height: 3px;
    background: #a2be2d;
    margin-right: 10px;
}
.tesimonial-sec-div{
	position: absolute;
	top: 0;
}
.contact-quick{
	/*background:url(../images/bg-ft.jpg) no-repeat;*/
	background-size: cover;
	width: 100%;
	height: 428px;
	display: grid;
	align-content: center;
}
.contact-quick h2,
.contact-quick h1{
	color: #fff;
	font-family: 'Red Hat Display', sans-serif;
}
.contact-quick h1{
	font-weight: 700;
}
.img-box{
	width: 100%;
	height: 350px;
	overflow: hidden;
	border-radius: 8px;
	background: #0c232e;
	position: relative;
}
.details{
	position: absolute;
	bottom: 0;
	padding-bottom: 10px;
	left: 0;
	color: #fff;
	padding-left: 20px;
/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+1,000000+1,000000+62,000000+100&0.65+0,0.12+0,0.81+62,0.81+62,0.81+97 */
background: -moz-linear-gradient(top,  rgba(0,0,0,0.12) 0%, rgba(0,0,0,0.13) 1%, rgba(0,0,0,0.81) 62%, rgba(0,0,0,0.81) 97%, rgba(0,0,0,0.81) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(0,0,0,0.12) 0%,rgba(0,0,0,0.13) 1%,rgba(0,0,0,0.81) 62%,rgba(0,0,0,0.81) 97%,rgba(0,0,0,0.81) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(0,0,0,0.12) 0%,rgba(0,0,0,0.13) 1%,rgba(0,0,0,0.81) 62%,rgba(0,0,0,0.81) 97%,rgba(0,0,0,0.81) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1f000000', endColorstr='#cf000000',GradientType=0 ); /* IE6-9 */


}
.comon-seed:hover .img-box img{
	transform: scale(1.1);
}
.details h5{
    font-weight: 600;
	text-transform: capitalize;
}
.details .ofer{
	background: rgb(255, 255, 255, 95%);
	text-align: center;
	padding: 10px 15px;
	color: #0c232e;
	display: table;
}
.img-box img{
	object-fit: cover;
	width: 100%;
	height: 100%;
	transform: scale(1);
	transition: all 0.5s;
	opacity: 0.5;
	
}
.big-img{
	width: 100%;
	height: 420px;
	border-radius: 15px;
	display: inline-block;
	overflow: hidden;
}
.td-menu li{
	cursor: pointer;
	text-align: center;
	padding: 10px 20px;
	margin: 0 10px;
	border-radius: 5px;
	background: #fff;
	font-family: 'Open Sans', sans-serif;
	font-weight: 600;
}
.produc-div{
	
	display: inline-block;
	height: 330px;
	overflow: hidden;
	border-radius: 5px;
	text-align: center;
	width: 100%;
	background: #fff;
	text-decoration: none;
	box-shadow: 0px 0px 50px 0px rgb(0 0 0 / 10%);
}
.produc-div figure{
	width: 100%;	
	margin: auto;
	height: 200px;
	overflow: hidden;
	display:inline-block;
	transition: all 0.5s;
	position: relative;
	background: rgba(0, 0, 0, 0.9);
	
}
.produc-div figure img{
	object-fit: cover;
	height: 100%;
	width: 100%;
	transform: scale(1);
	transition: all 0.5s;
}

.produc-div:hover figure img{
  transform: scale(1.1);
  opacity: 0.5;
}
.view-d-bn{
	background-image: linear-gradient(to right, #9cc026, #5faf21);
	position: absolute;
	left: 0;
	right: 0;
	text-align: center;
	display: table;
	margin: auto;
	color: #fff;
	top: 150%;
    transform: translateY(-50%);
	transition: all 0.5s;
	
}
.produc-div:hover .view-d-bn,
.produc-div:hover .cart-bn{
   top:50%;
}
.st-rating{
	color: rgb(255, 187, 0);
}

.produc-div h5{
	color: #0c232e;
	font-family: 'Open Sans', sans-serif;
	font-size: 16px;
	font-weight: 600;
	text-transform: capitalize;
}
.pro-titel{
	color: #0c232e;
	font-family: 'Open Sans', sans-serif;
	text-decoration: none;
	font-weight: 600;
	display: table;
	text-align: center;
	margin:5px auto 0;
}
.produc-div h4{
	font-family: 'Open Sans', sans-serif;
	color: #a2be2d;
	font-weight: 800;
}

.big-img img{
	object-fit: cover;
	width: 100%;
	height: 100%;
}
.small-img{
	width: 250px;
	height: 200px;
	border-radius: 15px;
	display: inline-block;
	overflow: hidden;
	position: relative;
	top:0;
	margin-left: auto;
	left: 15px;
	margin-top: -130px;
	margin-bottom:50px;
}
.small-img img{
	object-fit: cover;
	width: 100%;
	height: 100%;
}
.contact-bn{
	background: none;
	border: solid 2px #fff;
	color: #fff !important;
}
.consult-bn i{
	color: #fff;
	margin-left: 5px;
}
.contact-bn:hover{
	background: #fff !important;
	color: #00579a !important;
}
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.banner-div-content p{
	color:#fff;
	font-size:17px;
	margin: 0;

}
.banner-div-content figure{
	margin-top:90px;
	margin-bottom: 0;
	
}
.svg-div{
	position: relative;
	margin-top:-210px;
}
.about-div-pic{
	position:relative;
	/* height:520px; */
	overflow:hidden;
}
.pic-1{
	height: 348px;
    overflow: hidden;
    border-radius: 10px;
    width: 97%;
}
.pic-1 img{
	object-fit: cover;
	width: 100%;
	height: 100%;
}
.pic-2{
	position: absolute;
    z-index: 2;
    bottom: 0;
    right: 0;
    width: 60%;
    overflow: hidden;
    border-radius: 13px;
    height: 230px;
}
@keyframes fadeInleft{
	0%{
		opacity:0;
		transform:translateX(-30px);
	}
	100%{
		opacity:1;
		transform:translateX(0px);
	}
}
@keyframes fadeInRight{
	0%{
		opacity:0;
		transform:translateX(30px);
	}
	100%{
		opacity:2;
		transform:translateX(0px);
	}
}
@keyframes fadeInUp{
	0%{
		opacity:0;
		transform:translateY(-30px);
	}
	100%{
		opacity:1;
		transform:translateY(0px);
	}
}

form{
	margin-top:15px;
	width:100%;
	display:inline-block;
}
.banner-text h1{
	font-family: 'Open Sans', sans-serif;
	font-weight:700;
	margin-bottom:0;
	line-height:40px;
	color:#262b2b;
	font-size:30px;
}
.banner-text h1 span{
	font-weight:600;
	color:#000;
	text-transform:uppercase;
}
.banner-part > img{
	position:absolute;
	top:0;
	left:0;
	right:0;
	z-index:1;
}
.sec-d1{
	margin-top:60px;
	margin-bottom:60px;
	width:100%;
	display:inline-block;
}
.sec-d1 h4{
	position:relative;
	padding-left: 40px;
	color:#b2b74a;
}
.read-more-bn{
	background-image: linear-gradient(to right, #9cc026, #5faf21);
	color:#fff;
	font-size:14px;
	transition: all 0.5s;
	position: absolute;
    bottom: 22px;
}
.mobile-menu-sec .active-m a {
    background: #a2be2d;
    color: #fff;
}
.mobile-menu-sec li a, .mobile-menu-sec li .dropdown .btn {
    text-decoration: none;
    color: #212529;
    padding: 10px 5px 10px 10px;
    display: block;
    background: #f7f7f7;
    margin-bottom: 3px;
    width: 100%;
    text-align: left;
}
.side-media {
    list-style-type: none;
    margin-top: 15px;
    display: flex;
}
.side-media li {
    margin: 0 5px;
}
button.close-menu {
    color: #fff;
    background: #212529;
    border-radius: 5px;
    display: block;
    margin: 40px 0 0 0;
    width: 100px;
}
.mobile-menu-div{
	width: 320px;
}
.side-media li a {
    background: #212529;
    color: #fff;
    padding: 8px 12px;
    border-radius: 8px;
    display: inline-block;
}
.read-more-bn:hover{
	background: #212529;
	color: #fff;
}
.sec-d1 h3{
	box-shadow:0 1px 8px 0 rgb(0 0 0 / 6%);
	padding:15px;
	margin-top:15px;
	color:#b2b74a;
	border:1px solid rgb(243 243 243);
	width:auto;
	display:inline-block;
	margin-bottom:15px;
	
}
.sec-d1 h4:before{
	content:"";
	/*background-image:url(../images/barley-cl.png);*/
	background-repeat:no-repeat;
	width:30px;
	height:30px;
	position:absolute;
	left:0;
	top:0;
	background-size: cover;
}
.comon-hed{
	position:relative;
	padding-left: 40px;
	color:#262b2b;
	display:inline-block;
	width: auto;
    display: grid;
    justify-content: center;
    margin: auto;
	font-weight:700;
	
}
.comon-hed:before{
	content:"";
	/*background-image:url(../images/barley-cl.png);*/
	background-repeat:no-repeat;
	width:30px;
	height:30px;
	position:absolute;
	left:0;
	top:0;
	background-size: cover;
}
.sec-new-add{
	/*background:#363940 url(../images/ex-bg.jpg);*/
	padding: 35px 0;
    margin:88px 0 70px;
	color:#fff;
	background-size: cover;
	height: 362px;
	display: grid;
	align-content: center;
}
.sec-new-add h1{
	font-family: 'Merriweather', serif;
	font-weight: 900;
	font-size: 30px;
	text-transform: capitalize;
	position: relative;
	padding-bottom: 15px;
}
.sec-new-add h1:after,
.sec-project-div h1:after{
	position: absolute;
	content: "";
	left: 0;
	right: 0;
	width: 60px;
	background: #ada66b;
	height: 2px;
	margin: auto;
	display: table;
	bottom: 1px;
}
.sec-new-add i{
	color: #b2b74a;
	font-size:56px;
	margin-right:15px;
}
.sec-new-add h5 span{
	font-size:17px;
}
.sec-new-add h5{
	font-size:30px;
	line-height:30px;
}
.comon-st-div{
	display: flex;
    align-items: center;
}
.sec-new-add .row > div:nth-child(2) .comon-st-div{
	justify-content:center;
}
.sec-new-add .row > div:nth-child(3) .comon-st-div{
	justify-content:flex-end;
}
.sec-new-add .row > div:nth-child(4) .comon-st-div {
    justify-content: flex-end;
}
.what-project-div{
	background: #262b2b;
    margin: 48px 0;
}
.what-project-div h1,
.testimonal-sec h1{
	font-family: 'Merriweather', serif;
    font-weight: 900;
    font-size: 30px;
    text-transform: capitalize;
	position: relative;
	padding-bottom: 15px;
	color: #fff;
}
.book-sec .form-group{
	margin-bottom: 20px;
}
.book-sec{
	width: 82%;
}
.book-sec .form-control{
	border: none;
	background: #373c3c;
	height: 48px;
}
.awd-sec-div figure{
	width:100%;
	display: grid;
	border: solid 1px #e7e7e7;
    margin: auto;
    height: 113px;
	align-content: center;
	background: #fff;
	transition: all 0.5s;
	overflow: hidden;
}
.awd-sec-div figure img{
	transition: all 0.5s;
	transform:scale(1);
}
.awd-sec-div figure:hover{
	border: solid 1px #ada66b;
}
.awd-sec-div figure:hover img{
	transform:scale(1.1);
}
.book-sec textarea{
	height: 150px !important;
	resize: none;
}
.news-sec-div{
	
	position: relative;
	width: 100%;
	display: inline-block;
	padding-bottom: 30px;
}
.sec-project-div h1,
.sec-d4 h1,
.news-sec-div h1{
	font-family: 'Merriweather', serif;
    font-weight: 900;
    font-size: 30px;
    text-transform: capitalize;
	position: relative;
	padding-bottom: 15px;
}
.comon-case{
	text-align: center;
	border: solid 1px #ededed;
    padding: 30px 15px;
	text-decoration: none;
	display: inline-block;
}
.comon-case figure{
	width: 44px;
	display: inline-block;
}
.comon-case h5{
	font-weight: 700;
	font-size: 16px;
	color: #212529;
	transition:all 0.5s;
}
.comon-case h6{
	font-weight: 700;
	font-size: 14px;
	color: #b2b74a;
}
.comon-case p{
	font-size: 14px;
	line-height: 24px;
	color: #212529;
}
.comon-case figure img{
	transform:scale(1);
	transition:all 0.5s;

}
.comon-case:hover figure img{
	transform:scale(1.2);

}
.comon-case:hover h5{
	color: #ada66b;
}
.sec-project-div .row > div{
	padding: 0;
}
.sec-project-div .row > div:nth-child(2) .comon-case,
.sec-project-div .row > div:nth-child(4) .comon-case,
.sec-project-div .row > div:nth-child(5) .comon-case,
.sec-project-div .row > div:nth-child(7) .comon-case{
  background:#f3f3f3;
  border:solid 1px #f3f3f3;
}
.what-project-div .comon-hed{
	justify-content:left;
	color:#fff;
	margin-bottom:15px;
}
.what-project-div h4{
	margin-bottom:25px;
}
.what-project-div h4,
.what-project-div p{
	color:#fff;
	text-align:left;
}
.what-project-div ul{
	list-style-type:none;
	color:#fff;
	margin:0;
	padding:0;
	
}
.what-project-div figure{
	height:540px;
	margin:0;
	overflow:hidden;
}
.what-project-div .read-more-bn{
	background:#fff;
	color:#262b2b;
	margin-top:15px;
}
.what-project-div figure img{
	width:100%;
	height:100%;
	object-fit:cover;
}
.what-project-div ul li{
	display:flex;
	
}
.what-project-div ul li h5{
	font-size:16px;
	color: #b2b74a;
	font-weight:700;
	
}
.what-project-div ul li i{
	font-size: 33px;
    color: #b2b74a;
    margin-right: 10px;
    margin-top: -6px;
}

.from-sec-div{
	background:#fff;
	border-radius:100px;
    padding: 0 0 0 6px;
	box-shadow:0 1px 20px 0 rgb(0 0 0 / 20%);
	width:64%;
	margin:auto;
	
}
.list-work ul {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    list-style-type: none;
	flex-wrap: wrap;
}
.list-work ul li {
    padding: 10px 25px;
    position: relative;
    transition: 0.5s;
    cursor: pointer;
    background: #f3f3f3;
    border-radius: 30px;
    margin: 0 8px;
}
.each-items {
    overflow: hidden;
    height:260px;
    margin-bottom: 35px;
    display: inline-block;
    width: 100%;
    background: #fff;
    box-shadow: 0px 0px 50px 0px rgb(0 0 0 / 10%);
    padding: 15px;
    position: relative;
}
.each-items figure{
	height:230px;
	overflow:hidden;
}
.each-items figure > img{
	object-fit:cover;
	width:100%;
	height:100%;
}
.each-items .items-hover {
    background: rgba(50,54,87,0.8);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    text-align: center;
    display: grid;
    transform: scale(0,0);
    transition: 0.5s;
    align-content: center;
    color: #fff;
    font-size: 35px;
    justify-content: center;
}
.each-items:hover .items-hover {
    transform: scale(1,1);
}
.visit-bn {
    background: #fff;
    border-radius: 30px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #323657;
    font-size: 17px;
    padding: 5px 10px;
    margin-left: 20px;
	text-decoration:none;
}
.mixitup-control-active{
	background:#a2be2d !important;
	color:#fff;
}
.from-sec-div .comon-sec{
	display:flex;
	align-items: center;
    width: 45%;
	position:relative;
}
.from-sec-div .comon-sec i{
	font-size:18px;
	color:#dc3545;
}
.from-sec-div .comon-sec:nth-child(2):after{
	display:none;
}
.from-sec-div .comon-sec:after{
	width:1px;
	height:30px;
	background:#CCC;
	position:absolute;
	right:0;
	content:"";
}
.from-sec-div .comon-sec .form-control{
	border:none;
}
.btnh-submit{
	font-size:35px;
	color:#b2b74a;
	width:auto;
	
}
.how-it-work{
	/*background: url(../images/feature_image.png) top center no-repeat;*/
    background-position-y: 20px;
    margin-top: 60px;
}

.sec-d1 h1{
	font-size:30px;
	font-weight:900;
	text-transform:capitalize;
	line-height:40px;
	font-family: 'Merriweather', serif;
}
/* .sec-d1 h1 span{
	display:block;
	font-size:20px;
	font-weight:400;
	color:#4c4546;
} */

.comon-items{
	text-align:center;
	display:block;
}
.comon-items .icon-fs{
	width:90px;
	height:90px;
	border-radius:50%;
	display:grid;
	font-size:25px;
	align-content: center;
    text-align: center;
	color:#fff;
	margin:auto;
	margin-bottom:15px;
	border:3px dotted #d2d2d2;
	position:relative;
	background:#fff;
}
.comon-items .icon-fs i{
	color:#fff;
	position:relative;
	z-index:2;
}
.comon-items .icon-fs:after{
	width:70px;
	height:70px;
	border-radius:50%;
	background:#b2b74a;
	border-radius:50%;
	position:absolute;
	content:"";
	left: 7px;
    top: 7px;
	z-index:1;
}
.comon-items h5{
	display:block;
	font-size:15px;
	text-transform:uppercase;
	line-height:25px;
}
.comon-items h5 span{
	display:block;
	font-size:17px;
	text-transform:uppercase;
	font-weight:700;
}
.sec-d2{
	margin-top:50px;
	padding-top:50px;
	padding-bottom:70px;
}
.sec-d2 h1{
	font-size:36px;
}
.sec-d2 h1 span{
	font-weight:700;
}
.fl-sr-bn li{
	margin:0 5px;
}
.fl-sr-bn li a,
.fl-sr-bn li .btn{
	border:solid 1px #eee;
	background:#fff;
	transition:all 0.5s;
}
.cost-bn{
	border: solid 1px #eee;
    background: #fff;
    transition: all 0.5s;
}


.food-list a{
	text-decoration:none;
	color:#212529;
	border:none;
	box-shadow:0 1px 8px 0 rgb(0 0 0 / 6%);
}
.food-list .review-text i{
	color:#FC0;
}
.food-list figure{
	height:250px;
	overflow:hidden;
	margin:0;
}

.food-list figure > img{
	object-fit:cover;
	width:100%;
	height:100%;
	transition:all 0.5s;
}
.food-list .card-title{
	font-weight:700;
	color:#212529;
}
.off-text{
	position:absolute;
	left:10px;
	top:12px;
	background:#b2b74a;
	color:#fff;
	padding:5px 15px;
	border-radius:30px;
	z-index:1;
}
.food-list .card-footer{
	background-color:#fff;
}
.food-list .card-footer p{
	margin-bottom:5px;
	font-weight:700;
	color:#b2b74a;
}
.food-list .card-footer i{
	color:#b2b74a;
}
.food-list .card-footer small{
	color:#000 !important;
}
.card:hover figure img{
	transform:scale(1.2);
}

.sec-d3{
	display:grid;
	width:100%;
	padding-top:50px;
	/*background: url(../images/bg-banner.jpg) top center no-repeat;*/
	padding-bottom:50px;
	align-content: center;
    height: 400px;
}
.sec-d3 h1,
.sec-d3 p{
	color:#fff;
	text-align:center;
}
.sec-d3 p{
	margin-bottom:0;
	font-size:17px;
}
.sec-d3 h1{
	font-weight:700;
	text-transform:capitalize;
	color:#b2b74a;
}
.sec-d4{
	padding-top:50px;
	padding-bottom:50px;
}
.sec-d4 h1{
	font-weight:700;
}
.sec-d4{
	background:#fff;
}
.sec-d4 .comon-hed{
	display: table;
}
.sec-d4 .card{
	border: dashed 1px #eee;
    color: #fff;
    overflow: hidden;
    border-radius: 8px;
    width: 95%;
    padding-top: 20px;
}
.sec-d4 .card figure{
	height: 150px;
    overflow: hidden;
    width:150px;
    margin: auto;
    border-radius: 50%;
	border:solid 8px rgba(0,0,0, 6%);
}
.sec-d4 .card h5{
	font-weight:700;
	color:#262b2b;
	text-align:center;
	text-transform:capitalize;
	
}
.comon-text-sec h5{
	font-weight:700;
}
.comon-text-sec figure{
	height:250px;
	overflow:hidden;
	border-radius:8px;
	margin:0;
}
.comon-text-sec figure > img{
	width:100%;
	height:100%;
	object-fit:cover;
}
.vie-more-bn{
	text-transform:uppercase;
	margin:auto;
	background:#b2b74a;
	color:#fff;
	text-align:center;
	display:table;
	margin-top:35px;
}
.blog-sec-div .row > div{
	margin-bottom:40px;
}
.comon-text-sec h5,
.comon-text-sec p{
	padding-left:25px;
}
.comon-text-sec p{
	font-size:15px;
}
.comon-text-sec a{
	margin-left:25px;
	text-transform:capitalize;
}
.sec-d4 .card figure > img{
	object-fit:cover;
	width:100%;
	height:100%;
	transition:all 0.5s;
}
.sec-d4 .card-footer{
	background-color:#b50828;
	border:none;
}
.sec-d4 .card-footer p{
	margin-bottom:5px;
	color:#fff;
	font-weight:600;
	font-size:18px;
}
.sec-d4 .text-muted{
	color:#fff !important;
}
.sec-d5{
	margin-top:50px;
	padding:50px 0;
}
.sec-d5 h4,
.sec-d6 h4,
.sec-d7 h4{
	font-weight:700;
}
.restarn-link{
	display:flex;
	list-style-type:none;
	margin-top:25px;
	flex-wrap:wrap;
	width:100%;
	justify-content:center;
}
.restarn-link li{
	margin:5px;
}
.restarn-link li a{
	text-decoration:none;
	background:#fff;
	color:#212529;
	padding:10px;
	display:inline-block;
	border:1px solid rgb(232, 232, 232);
	text-transform:capitalize;
	font-size:15px;
	transition:all 0.5s;
	
}
.restarn-link li a:hover,
.restarn-link li a:hover i{
	background:#b2b74a;
	color:#fff;
}
.restarn-link li a i{
	color:#b2b74a;
}
.sec-d6,
.sec-d7{
	padding:50px 0;
}
.sec-d7{
	padding:30px 0;
	background:#b2b74a;
	color:#fff;
	position:relative;
	overflow:hidden;
}
.sec-d7 h1,
.sec-d7 .read-more-bn{
	position:relative;
	z-index:2;
}
.sec-d7 > row > div:nth-child(2){
	margin-right:auto;
}
.sec-d7 .read-more-bn{
	font-size: 17px;
}

.sec-d7 img{
	position:absolute;
	left:0;
	right:0;
	top:-380px;
	z-index:1;
	width:100%;
	opacity: 0.1;
	
}
.sec-d7 .restarn-link{
	columns: 3;
  -webkit-columns:3;
  -moz-columns: 3;
   list-style-position: inside;
  display:block;
}
footer p{
	font-size:13px;
	color:#fff;
	margin-bottom:10px;
	margin-top:12px;
	/*font-family: 'Libre Baskerville', serif;*/
}
/* footer .container div:last-child > div:nth-child(2){
	display: grid;
    justify-content: center;
} */
/* ========== check out page ==================*/
/* form wizad */
.form-wizard .form-wizard-header {
    text-align: center;
}
.form-wizard .form-wizard-steps {
    margin: 30px 0;
	justify-content: center;
	display: flex;
}
.form-wizard .form-wizard-steps li {
    width: 20%;
    float: left;
    position: relative;

}
.form-wizard .form-wizard-steps li.active span, .form-wizard .form-wizard-steps li.activated span {
    background-color: #a2be2d;
    color: #ffffff;
}
.form-wizard .wizard-fieldset.show {
    display: block;
}
.form-wizard .wizard-fieldset {
    display: none;
}
.form-wizard .form-wizard-steps li span {
    background-color: #dddddd;
    border-radius: 50%;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    position: relative;
    text-align: center;
    width: 40px;
    z-index: 1;
}
.form-wizard .form-wizard-steps li.active::after, .form-wizard .form-wizard-steps li.activated::after {
    background-color: #a2be2d;
    left: 50%;
    width: 50%;
    border-color: #a2be2d;
}
.form-wizard .form-wizard-steps li {
    width: 15%;
    float: left;
    position: relative;
}
.new-checkout{
	height: auto;
	min-height: auto !important;
}
.checkout-table tr:last-child td{
	border: none;
}
.checkout-table tr th{
	background: #f7f7f7;
	border: none;
	text-transform: capitalize;
	font-family: 'Red Hat Display', sans-serif;
}
.form-wizard .form-wizard-steps li::after {
    background-color: #f3f3f3;
    content: "";
    height: 5px;
    left: 0;
    position: absolute;
    right: 0;
    top: 74%;
    transform: translateY(-50%);
    width: 100%;
    border-bottom: 1px solid #dddddd;
    border-top: 1px solid #dddddd;
}
.form-wizard .form-wizard-steps li:last-child::after {
    width: 50% !important;
	
}
.form-wizard .form-wizard-steps li:last-child.active::after{
  display: none;
}
.form-wizard .form-control {
    font-weight: 300;
    height: auto !important;
    padding: 15px;
    color: #888888;
    background-color: #f1f1f1;
    border: none;
}
.comon-steps-div .form-select {
    background-color: #f1f1f1;
    padding: 15px;
    border: none;
}
.page-titel, .oder-right-details h2 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 20px;
}
.form-wizard .form-group {
    position: relative;
    margin: 13px 0;
}
.end-date, .cvv {
    display: flex;
    place-items: center;
}
.left-sec-d2 {
    margin-left: 15px;
}
.cvv .form-control {
    width: 60%;
    margin-right: 8px;
}
.end-date .form-select {
    margin-right: 5px;
    font-size: 13px;
}
#ac-2 {
    display: none;
}
.sucss-div {
    background: #fff;
    padding:40px 15px;
    margin: auto;
    
}
.img-success{
	margin: auto;
    width: 90px;
    display: table;
    margin-bottom: 20px;
}
.sucss-div i {
    font-size: 45px;
    text-align: center;
    color: #a2be2d;
    display: block;
    margin-bottom: 15px;
}
.order-dl {
    margin: 15px 0 0 0;
}
.form-wizard .wizard-form-error {
    display: none;
    background-color: #d70b0b;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px;
    width: 100%;
}
.order-dl h5 {
    border-bottom: solid 2px #a2be2d;
    margin-bottom: 15px;
}
.order-dl p span:last-child {
    margin-left: 8px;
    font-weight: 600;
    color: #a2be2d;
}

/* ========== login page ================= */
.login-div-modal .modal-content, .login-div-modal .modal-header {
    background: none;
    border: none;
}
.com-div-md {
    background: #fff;
    padding-top: 25px;
    padding-bottom: 25px;
}
.login-modal-pn {
    /* background: #f3f3f3; */
    padding: 10px 40px 30px 40px;
}
.login-div-modal h5{
	font-weight: 700;
    font-size: 25px;
}
.continue-bn {
	background-image: linear-gradient(to right, #9cc026, #5faf21);
    color: #fff !important;
    font-weight: 700;
    width: 100%;
    display: inline-block;
	box-shadow: 0 20px 30px 0 rgb(172 172 172 / 40%);
}
.continue-bn:hover{

}
.login-modal-pn .form-control{
	border: none;
	font-size: 15px;
	background: #f3f3f3;
	height: 45px;
	margin-bottom: 20px;
}
.user-icon{
	font-size: 35px;
}
.login-div-modal .close {
    transform: none;
    position: absolute;
    right: 22px;
    top: 27px;
    border: none;
    background: none;
    font-size: 29px;
}
.cm-select-login label {
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 14px;
    width: 92%;
}
/* ======== cart page ============== */
.pro-img{
	width: 20%;
}
.quantity-btn {
	background: transparent;
	border: none;
	outline: none;
	margin: 0;
	padding: 0px 8px;
	cursor: pointer;
	}
.quantity-btn svg {
width: 10px;
height: 10px;
}
.quantity-input {
outline: none;
user-select: none;
text-align: center;
width: 47px;
display: flex;
align-items: center;
justify-content: center;
background: transparent;
border: none;
}
.quantity-input::-webkit-inner-spin-button,
.quantity-input::-webkit-outer-spin-button {
-webkit-appearance: none;
margin: 0;
}
.close-bn-ps{
	background: #f7f7f7;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;
    text-align: center;
    padding: 0;
	transition: all 0.5s;
}
.close-bn-ps:hover{
	background: #a2be2d;
	color: #fff;
}
.cart-products-titel{
	width:131px;
	font-weight: 600;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space:nowrap;
	color: #212529;
	text-decoration: none;
}
.items-details .form-select{
	font-size: 12px;
	border: none;
	background-color: #f7f7f7;

}
.cupon-div .form-control{
	border: none;
	background-color: #f7f7f7;
	border-radius: 0;
	padding: 10px 0;
}
.form-new > div{
	border-bottom: solid 1px #f7f7f7;
	padding: 15px 0;
}
.form-new > div:first-child{
	margin-top: 0;
    padding-bottom: 0;
	padding-top: 0;
}
.form-new > div:last-child{
	margin-bottom: 0;
	border-bottom: none;
	
}
.items-details .quantity-control{
	background-color: #f7f7f7;
	padding: 4px 0;
}
.pro-img figure{
	width: 80px;
	overflow: hidden;
	height: 80px;
	display: inline-block;
}
.pro-img figure img{
	object-fit: cover;
	width: 100%;
	height: 100%;
}
.items-details .form-group{
	width: 23%;
}
.ceck-out-right-div {
    background: #fff;
    box-shadow: 0 1px 22px 0 rgb(0 0 0 / 9%);
    padding: 15px 20px;
    min-height: 270px;
}
.comon-order-items .items-details {
    /* display: flex;
    flex-wrap: wrap; */
    margin-top: 10px;
    justify-content: space-between;
    /* border-bottom: solid 1px #e9e8e8; */
}
.items-details h4 {
    font-weight: 700;
    font-family: 'Open Sans', sans-serif;
    color: #2c2b30;
	font-size: 18px;
	text-transform: capitalize;
}
.show-details label {
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    font-weight: 600;
    width: auto;
    margin-right: 10px;
}
.items-price h2 {
    font-size: 20px;
    font-weight: 700;
    color: #a2be2d;
}
.form-wizard .form-wizard-steps li.active::after, .form-wizard .form-wizard-steps li.activated::after {
    background-color: #a2be2d;
    left: 50%;
    width: 100%;
    border-color: #a2be2d;
}
.comon-steps-div {
    /* background: #fff;
    box-shadow: 0 1px 22px 0 rgb(0 0 0 / 9%);
    padding: 15px 20px; */
}
.ad-fm{
	background: #fff;
    box-shadow: 0 1px 22px 0 rgb(0 0 0 / 9%);
    padding: 15px 20px;
}
.paymeny{
	background: none;
	border: none;
}
.ad-fm .row > div .form-group {
    margin-bottom: 5px;
}
.comon-steps-div label {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    margin-bottom: 8px;
}
.left-sec-d1 h4,
.left-sec-d2 h4{
	font-size: 18px;
	font-weight: 700;
	font-family: 'Red Hat Display', sans-serif;
}

.show-details .form-group {
    margin: 0 !important;
    display: flex;
    align-items: center;
}
.show-details .form-control:disabled, .form-control[readonly], .show-details .form-select:disabled {
    background: none;
    padding: 0;
    border: none;
}
.show-details .form-select {
    width: 28%;
    margin-left: 0;
    font-family: 'Open Sans', sans-serif;
}
.oder-right-details {
    box-shadow: 0 1px 22px 0 rgb(0 0 0 / 9%);
    padding: 15px 20px;
	background: #fff;
}
.price-am, .delivery-am, .discount-am {
    display: flex;
    align-items: center;
    font-family: 'Open Sans', sans-serif;
    justify-content: space-between;
    font-size: 15px;
}
.price-am span {
    font-weight: 700;
    font-size: 20px;
}
.total-price{

	color:#a2be2d;
	padding: 10px 0;
}
.delivery-am span {
    font-size: 15px;
    font-weight: 500;
    color:#a2be2d;
}
.total-price span {
    font-weight: 700;
    font-size: 20px;
}
.total-price p {
    text-transform: uppercase;
    font-weight: 600;
}
.total-price span {
    font-weight: 700;
    font-size: 20px;
}
/* ======= prodcuts details ==============*/
.quantity-control {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: fit-content;
    margin: 0;
    background: #fff;
    border-radius: 6px;
    padding: 10px;
    margin: 15px 0;
}
.quantity-btn {
    background: transparent;
    border: none;
    outline: none;
    margin: 0;
    padding: 0px 8px;
    cursor: pointer;
}

.quantity-input {
    outline: none;
    user-select: none;
    text-align: center;
    width: 47px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: none;
}
.share-links li a {
    margin: 0 10px;
    color: #a2be2d;
}
.menu-img {
    height: 456px;
    border-radius: 8px;
    overflow: hidden;
}
.menu-img img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.bottom-details{
	box-shadow: 0 1px 8px 0 rgb(0 0 0 / 6%);
	background: #fff;
	padding: 30px;
	margin-top: 50px;
}
.comment-user-div {
    display: flex;
    flex-wrap: wrap;
    border-bottom: solid 1px #f1f1f1;
    padding-bottom: 20px;
    padding-top: 20px;
}
.comment-user-div .us-pic {
    width: 80px;
    height: 80px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 8px;
}
.user-dsl {
    width: 80%;
}
.user-dsl h6 span {
    font-size: 13px;
    color: #a2be2d;
    display: block;
    margin-top: 5px;
    margin-left: 8px;
    margin-right: 8px;
}

/* rating */
.rating { 
	border: none;
	margin:0px;
	margin-bottom: 18px;
	float: left;
  }
  
  .rating > input { display: none; } 
  
  .rating.star > label {
	  color: #bbb;
	  margin: 1px 20px 0px 0px;
	  background-color: #ffffff;
	  border-radius: 0;
	  height: 35px;
	  float: right;
	  width: 14px;
	  border:none;
  }
  fieldset.rating.star > label:before { 
	  margin-top: 0;
	  padding: 0px;
	  font-size:31px;
	  font-family: FontAwesome;
	  display: inline-block;
	  content: "\2605";
	  position: relative;
	  top: -9px;
  }
  .rating > label:before {
	  margin-top: 2px;
	  padding: 5px 12px;
	  font-size: 1.25em;
	  font-family: FontAwesome;
	  display: inline-block;
	  content: "";
  }
  .rating > .half:before { 
	content: "\f089";
	position: absolute;
  }
  .rating.star > label{
	background-color: transparent !important;
  }
  .rating > label { 
	  color: #fff;
	  margin: 1px 11px 0px 0px;
	  background-color: #78e2fb;
	  border-radius: 2px;
	  height: 16px;
	  float: right;
	  width: 16px;
	  border: 1px solid #c1c0c0;  
  }
  
  /***** CSS Magic to Highlight Stars on Hover *****/
  
  .rating:not(:checked) > label:hover, /* hover current star */
  .rating:not(:checked) > label:hover ~ label { 
	  background-color:#a2be2d !important;
	cursor:pointer;
  } /* hover previous stars in list */
  
  .rating > input:checked + label:hover, /* hover current star when changing rating */
  .rating > label:hover ~ input:checked ~ label, /* lighten current selection */
  .rating > input:checked ~ label:hover ~ label { 
	  background-color:#a2be2d !important;
	cursor:pointer;
  } 
  .rating.star:not(:checked) > label:hover, /* hover current star */
  .rating.star:not(:checked) > label:hover ~ label { 
	color:#a2be2d !important;
	background-color: transparent !important;
	cursor:pointer;
  } /* hover previous stars in list */
  
  .rating.star > input:checked + label:hover, /* hover current star when changing rating.star */
  .rating.star > label:hover ~ input:checked ~ label, /* lighten current selection */
  .rating.star > input:checked ~ label:hover ~ label { 
	color:#a2be2d !important;
	cursor:pointer;
	background-color: transparent !important;
  } 
  .star_rating{
		  width: 500px;
	  margin: 0 auto;
	  border: 1px solid #ff0000;
	  padding: 3px 30px 72px 35px;
	  box-shadow: 0 0 15px red;
	  margin-top: 2%;
	  border-radius: 14px;
  }

  .submit-review .form-control {
    border: none;
    background: #f3f3f3;
    height: 50px;
    margin-bottom: 15px;
}
.submit-review h5 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
}
.submit-review form{
	margin-top: 0;
}
.submit-review textarea {
    height: 150px !important;
    resize: none;
}
.menu-dl-right h3 {
    font-weight: 800;
    color: #a2be2d;
}
.menu-dl-right h5{
	font-weight: 400;
}
.menu-dl-right h5 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 15px;
}
.sixe-menu-q li a {
    border: solid 1px #bbb;
    font-family: 'Open Sans', sans-serif;
    color: #212529;
    padding: 10px;
    display: inline-block;
    cursor: pointer;
}
.sixe-menu-q li {
    margin: 0 5px;
}
.sixe-menu-q .active > a {
    border: solid 1px #a2be2d;
    background: #a2be2d;
}
.products-slide-1{
	position: relative;
	display: inline-block;
	width: 100%;
}
.products-slide-1 figure.mian-ppic{
	height: 387px;
}
.products-slide-1 figure.mian-ppic img{
	object-fit: cover;
	width: 100%;
	height: 100%;
}
.products-slide-1 .owl-nav{
   position: absolute;
   left: 0;
   right: 0;
   top:0;
}
.products-slide-1 .owl-nav .owl-prev{
	position: absolute;
	left: -22px;
    top: 11px;
}
.products-slide-1 .owl-nav .owl-next{
	position: absolute;
	right: 0px;
    top: 11px;
}
.thum-pic-slide figure{
	height: 80px;
}
.thum-pic-slide figure img{
	object-fit: cover;
	width: 100%;
	height: 100%;
}
/* ========== products page =================== */
.subpage-banner{
	overflow: hidden;
}
.subpage-banner h1{
	text-transform: capitalize;
	font-weight: 700;
}
.sub-pages{
	background:#f7f7f7 ;
	position: relative;
	padding-top: 60px;
}
.sub-pages h2{
	font-size: 23px;
	font-weight: 700;
	text-transform: capitalize;
	font-family: 'Red Hat Display', sans-serif;
}
.listed-bn li{
	margin: 0 7px;
}
.listed-bn li a{
	color: #161616;
	font-size: 24px;
	cursor: pointer;
}

.filter-sec-div{
	box-shadow: 0 1px 8px 0 rgb(0 0 0 / 6%);
    background: #fff;
    border-radius: 8px;
    width: 100%;
    padding: 20px;
    margin: 0;
}
.filter-sec-div .accordion-item{
	border: none;
	
}
.filter-sec-div .accordion-button{
	box-shadow: none;
	background-color: #f7f7f7 !important;
	color: #132731;
	font-weight: 600;
	text-transform: capitalize;
}
/* price filter */
.range-slider .rangeValues {
    display: block;
}
.range-slider input[type=range] {
    -webkit-appearance: none;
    border: 1px solid white;
    width: 100%;
    position: absolute;
    left: 0;
}
.range-slider {
    width: 100%;
    text-align: center;
    position: relative;
}


.button-group-pills .btn {
    border-radius: 5px;
    font-weight: 500;
    margin-bottom: 15px;
    margin-left: 10px;
    border-color: #979899;
    background-color: #FFF;
    color: #979899;
    display: grid;
    align-content: center;
    font-size: 15px;
}

.button-group-pills input {
    appearance: none;
}
.button-group-pills .btn:hover {
    border-color: #a2be2d;
    background-color: #a2be2d;
    color: #FFF;
    box-shadow: none;
}
.button-group-pills .btn.active {
    border-color: #a2be2d;
    background-color: #a2be2d;
    color: #FFF;
    box-shadow: none;
    box-shadow: none;
}
.cate-sec-check-div .form-check-input{
    position: relative;
	border: none;
	cursor: pointer;
	
}
.cate-sec-check-div .form-check-input::before{
	width: 20px;
	height: 20px;
	background: #fff;
	border-radius: 0;
	border: solid 2px #bbb;
	display: inline-block;
	content: "";
	left: 0;
	top:0;
	pointer-events: none;
	display: grid;
    align-content: center;
    font-size: 13px;
}
.cate-sec-check-div .form-check-input:checked,
.cate-sec-check-div .form-check-input:focus{
	box-shadow: none;
}
.cate-sec-check-div .form-check-input:checked::before{
	content: "\f00c";
	font-family: 'FontAwesome';
	color: #a2be2d;
	border-color: #a2be2d;
	text-align: center;
}
.cate-sec-check-div .form-check label{
	margin-left: 10px;
	font-weight: 600;
	font-size: 15px;
	cursor: pointer;
	text-transform: capitalize;
}
.cate-sec-check-div .form-check{
	margin-bottom: 15px;
}
.morect-bn{
	color: #b2b74a;
}
#more-div-text{
	display: none;
}
.listed-bn ul li .active {
    color: #b2b74a;
}
.sub-bn{
	background: #b2b74a;
	color:#fff;
	margin-top:8px;
}
.subcribe-div h2{
	font-weight: 800;
	font-size: 25px;
}
.cupon-div h5{
	font-weight: 800;
	font-family: 'Inter', sans-serif;
}
.subcribe-div .form-control{
	border: none;
	border-radius: 5px 0 0 5px;
	height: 45px;
}
.sec-d7 .restarn-link li a{
	background:#b2b74a;
	color:#fff;
	width:80%;
}
.subcribe-bn{
	background:#212529;
	color: #fff;
	height: 45px;
	border-radius: 0 5px 5px 0;
}
.socal{
	justify-content: start;
    display: flex;
}
.socal li a{
	color:#fff;
	margin:0 5px;
	text-decoration:none;
}
.sec-d7 .restarn-link li a:hover,
.sec-d7 .restarn-link li a:hover i{
	background:#212529;
}
.sec-d7 .restarn-link li a i{
	color:#fff;
}
.sec-d7 .form-group{
	border:solid 1px #eee;
	overflow:hidden;
}
.sec-d7 .form-control{
	border:none;
}
.sec-d7 .form-group .btn{
	background:#b2b74a;
	transition:all 0.5s;
	color:#fff;
	border:none;
	border-radius:0;
}
.sec-d7 .form-group .btn:hover{
	background:#212529;
}
.sec-d7 h5{
	font-weight:600;
	text-transform:capitalize;
}
/* filter gird css */

.item.list-group-item {
    float: none;
    width: 48%;
    margin-bottom: 30px;
    -ms-flex: 0 0 48%;
    flex: 0 0 48%;
    max-width: 48%;
    padding: 0;
    border: 0;
    height:223px;
	overflow: hidden;
    border-radius: 8px;
	transition: all 0.5s;
	box-shadow: 0 1px 8px 0 rgb(0 0 0 / 6%);
	
}
.comon-items-d1{
	display: inline-block;
}
.item.list-group-item .right-list-div{
	padding-left: 20px;

}
.comon-items-d1 figure{
	height: 184px;
	width: 100%;
	display: inline-block;
	overflow: hidden;
	border-radius:5px;
	position: relative;
}
.comon-items-d1 hr{
	background: #bbb;
}
.comon-items-d1 figure img{
	object-fit: cover;
	width: 100%;
	height: 100%;
	transform: scale(1);
	transition: all 0.5s;
}
.comon-items-d1:hover figure img{
	transform:scale(1.1);
}
.comon-items-d1 h6{
	color: #a2be2d;
	font-size: 15px;
	font-weight: 600;
}
.comon-items-d1 .titel-product{
	font-weight: 700;
	font-size: 18px;
	color: #212529;
	text-decoration: none;
	text-overflow: ellipsis;
	width: 100%;
	font-family: 'Red Hat Display', sans-serif;
	text-transform: capitalize;
	white-space: nowrap;
	display: inline-block;
	overflow: hidden;
}
.comon-items-d1 h2{
	font-size: 25px;
	color: #a2be2d;
}
.strat-r{
	 background: #a2be2d;
	 color: #fff;
	 font-weight: 600;
	 font-size: 12px;
	 position: absolute;
	 left: 13px;
	 top:10px;
	 z-index: 1;
}
span.fw-bolder{
	font-size: 12px;
}
.item.list-group-item .left-div-list{
    width: 40%;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
}
.item.list-group-item .right-list-div {
    padding-top: 0;
    padding-bottom: 0;
    width: 60%;
}
.grid-group-item {
    margin-bottom: 30px;
    overflow: hidden;
    border-radius: 8px;
	transition: all 0.5s;
}
.grid-group-item .left-div-list,
.grid-group-item .right-list-div{
	width: 100%;
}
.comon-items-d1 {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    flex-wrap: wrap;
    width: 100%;
    background-color: #fff;
    /* box-shadow: 0 1px 8px 0 rgb(0 0 0 / 6%); */
    border-radius: 8px;
	overflow: hidden;
	/* box-shadow: 0 1px 8px 0 rgb(0 0 0 / 6%); */
}
.simple-pagination ul {
    margin: 0 0 20px;
    padding: 0;
    list-style: none;
    text-align: center;
    display: flex;
    place-items: center;
}
.simple-pagination li a, .simple-pagination li span {
    color: #666;
    padding: 5px 10px;
    text-decoration: none;
    border: 1px solid #EEE;
    background-color: #FFF;
}
.simple-pagination .prev.current, .simple-pagination .next.current {
    background: #d42a23;
    position: inherit;
    height: 30px;
}
.simple-pagination .current {
    color: #FFF;
    background-color: #090909;
    border: none;
}
.view-products {
	font-weight: 600;
}
.comon-likke,
.share-bn{
	cursor: pointer;
	color: #bbb;
}
.short-link{
	color: #a2be2d !important;
}

.view-products i{
	margin-left: 5px;
}
.grid-group-item .comon-items-d1{
	flex-direction: column;
}


/* footer css */

footer{
	padding:0 0 0 0;
	display: inline-block;
    width: 100%;
	background:#0c232e;
	/* background-size:cover; */
	
}
footer .footer-bottom-div > div:nth-child(3) .comon-footer{
	display: grid;
    justify-content:center;
}
footer .footer-bottom-div > div:nth-child(4) .comon-footer{
	display: grid;
    justify-content: end;
}
footer .footer-bottom-div > div:nth-child(2) .comon-footer{
	display: grid;
    justify-content: center;
}
footer .row > div:nth-child(5) .comon-footer{
	display: grid;
    justify-content: end;
}
.comon-footer h5{
	font-size:18px;
	font-weight:600; 
	text-transform:capitalize;
	color:#fff;
}
.comon-footer ul{
	margin:0;
	padding: 3px 0 0 18px;
    margin-bottom: 15px;
    list-style: disc;
    color: #fff;
}
.comon-footer ul li{
	line-height:30px;
}
.comon-footer ul li a{
	text-decoration:none;
	font-size:14px;
	color:#fff;
	transition:all 0.5s;
}
.comon-footer ul li a:hover{
	color:#b2b74a;
}

.comon-footer .list-unstyled{
	list-style: none !important;
	padding-left:0;
}
.comon-footer .list-unstyled li a{
	margin:0 5px;
	font-size:18px;
}
.sec-d8{
	padding:0;
	background:#b2b74a;
	height: 294px;
}
.text-apps h3{
	font-size:30px;
	font-weight:700;
	text-transform:capitalize;
	color:#fff;
}
.text-apps{
	display: grid;
    align-content: center;
    height: 100%;
}
.text-apps p{
	color:#fff;
}
.sec-d8 figure{
	position:relative;
	top:-20px;
}
.sec-d8 figure > img{
	display: block;
    margin-left: auto;
}
.sec-d8 .row{
	width:90%;
}
.active-new2{
	background:#b2b74a !important;
	color:#fff;
}












.range-slider {
  width: 100%;
  text-align: left;
  position: relative;
}
.range-slider .rangeValues {
  display: block;
  font-weight: 800;
}

.range-slider input[type=range] {
  -webkit-appearance: none;
  border: 1px solid white;
  width: 100%;
  position: absolute;
  left: 0;
}

.range-slider input[type=range]::-webkit-slider-runnable-track {
  width: 300px;
  height: 5px;
  background: #ddd;
  border: none;
  border-radius: 3px;
}

.range-slider input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #a2be2d;
  margin-top: -6px;
  cursor: pointer;
  position: relative;
  z-index: 1;
}

.range-slider input[type=range]:focus {
  outline: none;
}

.range-slider input[type=range]:focus::-webkit-slider-runnable-track {
  background: #ccc;
}

.range-slider input[type=range]::-moz-range-track {
  width: 300px;
  height: 5px;
  background: #ddd;
  border: none;
  border-radius: 3px;
}

.range-slider input[type=range]::-moz-range-thumb {
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #21c1ff;
}

/*hide the outline behind the border*/
.range-slider input[type=range]:-moz-focusring {
  outline: 1px solid white;
  outline-offset: -1px;
}

.range-slider input[type=range]::-ms-track {
  width: 300px;
  height: 5px;
  /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
  background: transparent;
  /*leave room for the larger thumb to overflow with a transparent border */
  border-color: transparent;
  border-width: 6px 0;
  /*remove default tick marks*/
  color: transparent;
  z-index: -4;
}

.range-slider input[type=range]::-ms-fill-lower {
  background: #777;
  border-radius: 10px;
}

.range-slider input[type=range]::-ms-fill-upper {
  background: #ddd;
  border-radius: 10px;
}

.range-slider input[type=range]::-ms-thumb {
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #21c1ff;
}

.range-slider input[type=range]:focus::-ms-fill-lower {
  background: #888;
}

.range-slider input[type=range]:focus::-ms-fill-upper {
  background: #ccc;
}


.owl-prev:after {
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    content: "\f104";
    left: 0;
    right: 81px;
    margin: auto;
    font-family: 'FontAwesome';
    color: #fff;
    display: grid;
    background: #212529;
    align-content: center;
}
.owl-prev span {
    display: none;
}
.owl-next span {
    display: none;
}
.owl-next:after {
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    content: "\f105";
    left: 0;
    right: 0;
    margin: auto;
    font-family: 'FontAwesome';
    color: #fff;
    display: grid;
    background: #212529;
    align-content: center;
}

/* sub page */
.subpage-banner{
	height: 260px;
}
.subpage-banner h1{
	font-size: 34px;
    margin-top: 53px;
	font-family: 'Red Hat Display', sans-serif;
	font-weight: 700;
}
.new-input{
	padding: 12px 0 12px 5px;
	background-color: #f7f7f7;
	font-size: 12px;
	border: none;
}
.subpage-banner ul li{
	color: #fff;
	margin: 0 15px;
	position: relative;
}
.subpage-banner ul li:after{
	position: absolute;
	right: -18px;
	content: "\f105";
	font-family:'FontAwesome';
}
.subpage-banner ul li:last-child:after{
	display: none;
}
.subpage-banner ul li a{
	text-decoration: none;
	color: #fff;
}
.call-details-div{
	background: #00579a;
    color: #fff;
    height: 290px;
    display: grid;
    align-content: center;
    border-radius: 8px;
}
.call-details-div h2{
	text-transform: uppercase;
    font-weight: 700;
    font-size: 26px;
    line-height: 33px;
}
.call-details-div h6{
	font-weight: 500;
	line-height: 27px;
}
.call-details-div h6 span{
	font-size: 26px;
	margin-top: 10px;
}
.comon-services-pge figure{
	
}
.comon-severice-dl ul{
	column-count: 2;
    column-gap: 20px;
}
.comon-severice-dl h2{
	font-weight: 700;
	text-transform: capitalize;
}
.comon-severice-dl p{
	font-size: 15px;
}
.aplications-part{
	margin-top: 50px;
	display: inline-block;
	width: 100%;
	background: #f6f8fd;
}
/* .service-dl-pic img{
	width: 400px;
} */
.care-text{
	width: 100%;
	display: inline-block;
	position: relative;
}
.comon-severice-dl ul li{
	box-shadow: 2.5px 4.33px 30px 0 rgb(64 87 191 / 10%);
    padding: 10px 20px;
    background: #fff;
    display: inline-block;
    border-radius: 8px;
    margin-bottom: 18px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    font-size: 14px;
    text-transform: capitalize;
}
.comon-severice-dl ul li i{
	color: #00579a;
}
.php-part{
	margin-top: 100px;
	display: inline-block;
	width: 100%;
}

.project-menu ul{
	display: flex;
	justify-content: center;
}
.project-menu ul li{
	margin: 0 10px;
	cursor: pointer;
	background: #bbb;
	background: #e9e9e9;
    padding: 10px 20px;
    border-radius: 8px;
	font-family: 'Poppins', sans-serif;
}
.comon-projets{
	width: 100%;
	display: inline-block;
	box-shadow: 5px 7px 15px 2px rgb(82 90 101 / 12%);
	padding: 15px;
	margin-bottom: 35px;
}
.comon-projets figure{
	height: 250px;
	overflow: hidden;
	margin-bottom: 0;
}
.comon-projets figure img{
	object-fit: cover;
	width: 100%;
	height: 100%;
}

.comon-career-bl{
	width: 100%;
	box-shadow: 5px 7px 15px 2px rgb(82 90 101 / 12%);
	padding: 34px;
	height: 360px;
	display: grid;
	align-content: center;
}
.comon-career-bl h2{
	font-size: 22px;
}
.comon-career-bl ul li{
	margin: 0 0 8px 0;
}
.comon-career-bl p{
	font-size: 14px;
	line-height: 22px;
}
.comon-career-bl ul li span{
	color:#00579a;
	font-weight: 600;
	margin-right: 8px;
}
.view-details,
.apy-bn,
.job-ap{
	background:#00579a;
	color: #fff;
	font-weight: 600;
	transition: all 0.5s;
	border: solid 2px #00579a;
}
.view-details:hover,
.apy-bn:hover,
.job-ap:hover{
	background:none;
	color: #a2be2d;
	border: solid 2px #a2be2d;
}
.cm-text-n h6{
	color: #a2be2d;
}
.cm-text-n ul li{
	margin-bottom: 20px;
}
.cm-text-n ul li .icon-c{
	width: 45px;
	height: 45px;
	overflow: hidden;
	border-radius: 50%;
	display: grid;
	align-content: center;
	background: #a2be2d;
	color: #fff;
	justify-content: center;
	font-size: 18px;

}
.text-c{
	font-weight: 600;
	font-size: 18px;
	margin-left: 15px;
	font-family: 'Red Hat Display', sans-serif;
}
.text-c small{
	font-weight: 600 !important;
	font-size: 19px;
    color: #a2be2d;
}
.conatct-form-div h2,
.cm-text-n h2{
	font-size: 25px;
	/*font-family: 'Libre Baskerville', serif;*/
}
.conatct-form-div h6{
	color: #a2be2d;
}
.cr-details{
	margin-top: 20px;
}
.cr-details h5{
	font-weight: 600;
}
.cr-details p{
	font-size:15px;
}
.cr-details ul li{
	margin-bottom: 10px;
}
.comon-career-bl ul li:first-child{
	margin-left: 0;
}
.conatct-form-div{
	display: inline-block;
    width: 100%;
    padding: 20px;
    box-shadow: 5px 7px 15px 2px rgb(82 90 101 / 12%);
}
/* pricing page */

.basic .pricing-header {
	background-color: #4b7bec;
  }
  .basic .price-circle {
	border: 10px solid #4b7bec;
	transition: all 0.4s;
  }
  .basic:hover .price-circle {
	border-width: 5px;
  }
  .basic .buy-now:hover {
	background-image: none !important;
	background-color: #4b7bec !important;
	box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.4);
  }
  
  .eco .pricing-header {
	background-color: #f7b731;
  }
  .eco .price-circle {
	border: 10px solid #f7b731;
	transition: all 0.4s;
  }
  .eco:hover .price-circle {
	border-width: 5px;
  }
  .eco .buy-now:hover {
	background-image: none !important;
	background-color: #f7b731 !important;
	box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.4);
  }
  
  .pro .pricing-header {
	background-color: #26de81;
  }
  .pro .price-circle {
	border: 10px solid #26de81;
	transition: all 0.4s;
  }
  .pro:hover .price-circle {
	border-width: 5px;
  }
  .pro .buy-now:hover {
	background-image: none !important;
	background-color: #26de81 !important;
	box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.4);
  }
  
  .business .pricing-header {
	background-color: #a55eea;
  }
  .business .price-circle {
	border: 10px solid #a55eea;
	transition: all 0.4s;
  }
  .business:hover .price-circle {
	border-width: 5px;
  }
  .business .buy-now:hover {
	background-image: none !important;
	background-color: #a55eea !important;
	box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.4);
  }
  


  
  .column {
	flex: 25%;
	padding: 10px;
	width: calc(33.3% - 30px);
	box-sizing: border-box;
  }
  @media screen and (max-width: 980px) {
	.column {
	  flex: 50%;
	  display: block;
	}
  }
  @media screen and (max-width: 700px) {
	.column {
	  flex: 100%;
	  display: block;
	}
  }
  
  .pricing-card {
	-webkit-transition: 0.4s background-color ease;
	-ms-transition: 0.4s background-color ease;
	transition: 0.4s background-color ease;
	height: 600px;
	background-color: white;
	-moz-border-radius: 20px;
	-webkit-border-radius: 20px;
	border-radius: 20px;
	position: relative;
	transition: all 0.4s;
	box-shadow: 5px 7px 15px 2px rgb(82 90 101 / 12%);
  }
  .pricing-card:hover {
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
	transform: scale(1.05);
  }
  .pricing-card .popular {
	position: absolute;
	top: 0;
	right: 5%;
	width: auto;
	padding: 10px;
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
	background-color: #eb3b5a;
	color: white;
	font-size: 12px;
	z-index: 1;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }
  .pricing-card .badge-box {
	padding: 0 40px;
	margin-top: 80px;
  }
  .pricing-card .badge-box span {
	display: inline-block;
	border: 1px solid #4b7bec;
	padding: 4px 12px;
	border-radius: 25px;
	overflow: hidden;
	color: #4b7bec;
  }
  .pricing-card .pricing-header {
	width: 100%;
	height: 150px;
	position: relative;
	border-radius: 20px 20px 0 0;
	-webkit-border-radius: 20px 20px 0 0;
	-moz-border-radius: 20px 20px 0 0;
	/*border-bottom: 20px solid $primary;*/
	/*
	border-radius: $radius $radius 150px 150px;
	-webkit-border-radius: $radius $radius 150px 150px;
	-moz-border-radius: $radius $radius 150px 150px;
	*/
  }
  .pricing-card .pricing-header .plan-title {
	font-size: 24px;
	color: white;
	position: relative;
	top: 25%;
  }
  .pricing-card .pricing-header .price-circle {
	width: calc(33.3% - 30px);
	width: 120px;
	height: 120px;
	border-radius: 100%;
	left: calc(50% - 60px);
	top: 60%;
	background-color: white;
	position: absolute;
  }
  .pricing-card .pricing-header .price-circle .info {
	display: block;
	font-size: 12px;
	font-weight: bold;
	color: gray;
  }
  .pricing-card .pricing-header .price-circle .price-title {
	display: block;
	font-size: 28px;
	padding: 28px 0 0;
	font-weight: bold;
  }
  .pricing-card .pricing-header .price-circle .price-title small {
	font-size: 18px;
  }
  .pricing-card .pricing-header h2 {
	position: relative;
	top: 40%;
	color: #fff;
  }
  .pricing-card ul {
	margin: 10px 0 0 0;
	padding: 0;
  }
  .pricing-card ul li {
	list-style-type: none;
	display: block;
	padding: 15px 0 15px 0;
	margin: 0;
	border-bottom: 1px solid #f2f2f2;
  }
  .pricing-card .buy-button-box {
	width: 100%;
	float: left;
	margin-top: 30px;
  }
  .pricing-card .buy-button-box .buy-now {
	text-decoration: none;
	color: white;
	padding: 10px 30px;
	border-radius: 20px;
	background-image: linear-gradient(to left, #a55eea, #45aaf2);
	margin-top: 20px;
  }
/* blog details */
.blog-post{
	border-bottom: solid 1px #f3f3f3;
	width: 100%;
	display: inline-block;
	margin-bottom: 25px;
}
.blog-post figure{
	width: 100%;
	height: 320px;
	overflow: hidden;
}
.blog-post figure img{
	object-fit: cover;
	width: 100%;
	height: 100%;
}
.blog-post .share-div{
	border-bottom: solid 1px #f3f3f3;
	margin-bottom: 15px;
}
.blog-post ul li{
	margin: 0 10px;
}
.blog-post h2{
	font-size: 25px;
	font-weight: 700;
	margin-bottom: 10px;
	text-transform: capitalize;
}
.comon-com-div{
	border-bottom: solid 1px #f3f3f3;
	display: inline-block;
	width: 100%;
	padding: 10px 0;
}
.comon-com-div figure{
	width: 63px;
    height: 63px;
    overflow: hidden;
    border-radius: 50%;
}
.comon-com-div figure img{
	object-fit: cover;
	width: 100%;
	height: 100%;
}
.comment-text{
	width: 89%;
}
.comment-text span{
	font-size: 13px;
    font-weight: 600;
    color: #00579a;
}
.comment-text p{
	font-size: 14px;
	margin-top: 7px;
}
.comment-sec-part h2,
.leave-sec-part h2,
.category-div h2,
.recent-post-div h2,
.tag-div h2{
	font-size: 23px;
	font-weight: 700;
}
.leave-sec-part{
	display: inline-block;
	width: 100%;
	padding: 10px 20px;
	box-shadow: 5px 7px 15px 2px rgb(82 90 101 / 12%);
	margin: 30px 0;
	padding-bottom: 20px;
}
.leave-sec-part .form-group{
	margin-top: 20px;
}
.leave-sec-part .form-control,
.conatct-form-div .form-control{
	border: none;
	background: #f1f1f1;
	height: 45px;
}
.conatct-form-div .form-control{
	margin-bottom: 20px;
}
.leave-sec-part textarea,
.conatct-form-div textarea{
	height: 100px !important;
	resize: none;
}
.leave-sec-part .subimt-comment,
.subimt-message,
.quick-contact .subimt-comment{
	background: #a2be2d;
	color: #fff;
	font-weight: 600;
	
}
.subimt-message{
	width: 90px;
	text-transform: uppercase;
}
.comment-sec-part > div:last-child{
	border-bottom: none;
}
.cv-upload-div .form-control,
.cv-upload-div .form-select{
	border: none;
    background-color: #f1f1f1;
    height: 45px;
	margin-bottom: 15px;
}
.cv-up h5{
	font-weight: 700;
}
.category-div,
.job-ds-div{
	background: #f1f1f1;
	padding: 30px;
	display: inline-block;
	width: 100%;
}

.comon-jobs-div{
	margin-top: 20px;
	border-bottom: solid 1px #fff;
	padding-bottom: 20px;
}
.job-ds-div > div:last-child{
	border: none;
}
.job-ds-div h5{
	color: #00579a;
	margin-bottom: 8px;
}
.category-div ul li{
	border-bottom: solid 1px #fff;
	margin-bottom: 10px;
	padding-bottom: 10px;
}
.category-div ul li:last-child{
	border-bottom: none;
}
.category-div ul li a{
	text-decoration: none;
	color: #212529;
	font-weight: 600;
	transition: all 0.5s;
	text-transform: capitalize;
	font-family: 'Red Hat Display', sans-serif;
}
.tag-div ul{
	display: flex;
	flex-wrap: wrap;
	
}
.tag-div ul li{
	margin:0 10px;
}
.tag-div ul li a{
	text-decoration: none;
	color: #212529;
	margin-top: 15px;
	transition: all 0.5s;
	font-weight: 600;
	text-transform: capitalize;
}
.tag-div ul li a:hover{
	color: #00579a;
}
.category-div ul li a:hover{
	color: #a2be2d;
	font-size: 700;
}
.recent-post-div-insiide a{
	text-decoration: none;
	border-bottom: solid 1px #fff;
	display: inline-block;
	width: 100%;
	padding-bottom: 20px;
	margin-bottom: 20px;
}
.recent-post-div-insiide a:hover figure img{
	transform: scale(1.1);
}
.recent-post-div-insiide a:hover h5{
	color: #a2be2d;
}
.recent-post-div-insiide a:last-child{
	border: none;
	margin: 0;
}
.recent-post-div,
.tag-div{
	background: #f1f1f1;
	padding: 30px;
	display: inline-block;
	width: 100%;
}
.recent-post-div figure{
	width: 90px;
	height:60px;
	overflow: hidden;
	margin: 0;
	transform: scale(1);
	
}
.recent-post-div figure img{
	object-fit: cover;
	width: 100%;
	height: 100%;
	transition: all 0.5s;
}
.recent-post-div h5{
	width: 60%;
	color: #212529;
	font-size: 17px;
	line-height: 25px;
	font-weight: 600;
	transition: all 0.5s;
	text-transform: capitalize;
}
.about-mn-pic{
	position: relative;
	height: 550px;
}
.about-mn-pic figure:last-child{
	height: 350px;
    overflow: hidden;
    position: absolute;
    z-index: 2;
    bottom: 20px;
    width: 70%;
    left: -20px;
	border-radius: 8px;

}
.about-mn-pic figure:first-child{
	height: 480px;
	overflow: hidden;
	border-radius: 8px;
}
.about-mn-pic figure img{
	object-fit: cover;
	width: 100%;
	height: 100%;
}
.about-page  h2{
	font-weight: 700;

}
.our-mmison{
	position: relative;
	display: inline-block;
	width: 100%;
	background: #f6f8fd;
	margin-top: 125px;
}
.our-mmison ul,
.our-gols ul{
	column-count: 2;
	column-gap: 15px;
}
.our-mmison ul li,
.our-gols ul li{
	box-shadow: 2.5px 4.33px 30px 0 rgb(64 87 191 / 10%);
    padding: 10px 20px;
    background: #fff;
    display: inline-block;
    border-radius: 8px;
    margin-bottom: 18px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 14px;
    text-transform: capitalize;
}
.our-mmison ul li i,
.our-gols ul li i{
	color: #1c5bea;
    margin-right: 5px;

}
.our-gols{
	display: inline-block;
	width: 100%;
	position: relative;
	margin-top: 100px;
}
@media (max-width:1024px){
	.what-project-div figure{
		height:664px;
	}
	.comon-pic-news figure > a{
		height:100%;
	}
	.comon-pic-news figure > a > img{
		width: 100%;
        height: 100%;
        object-fit: cover;
	}
	.sec-d7 img{
		top:-50px;
	}
	.read-more-bn{
		bottom: -5px;
	}
	.dtails-idv-text h5{
		font-size: 15px;
	}
}
@media (max-width: 990px){
	header {
		height: auto;
		top: 0;
		background: #fff;
	}
	.mn-head .sc-logo {
		display: block;
	}
	.com-link {
		color: #000;
	}
	.com-link:nth-child(1) {
		display: none;
	}
	.cart-new-icon {
		display: block !important;
	}
	.offer-div-sec{
		padding: 40px 0;
		height: auto;
	}
	.ds-logo{
		display: none;
	}
	.item.list-group-item{
		max-width: 100%;
		flex: 0 0 100%;
		width: 100%;
	}
	.subpage-banner{
		height: 250px !important;
	}
	.subpage-banner .banner-div-content{
		height: 139px;
	}
	.about-mn-pic figure:first-child{
		height: 300px;
	}
	.about-mn-pic figure:last-child{
		height: 240px;
		bottom: 140px;
		width: 100%;
	}
	.silder-div-text .owl-next {
		right: 40px;
	}
	.silder-div-text .owl-nav .owl-prev {
		left: 0px;
	}
	.read-more-bn{
		bottom: -10px;
	}
	.oder-right-details,
	.new-checkout{
		margin-top: 20px;
	}
	
}
@media (max-width: 812px){
	.search-bn {
		width: 100%;
		margin-top: 15px;
	}
	.about-div-pic {
       margin-top: 50px;
    }
	.comon-st-div {
      display: block;
      text-align: center;
    }
	.list-work ul {
       flex-direction: column;
    }
	.list-work ul li {
          margin-bottom: 15px;
    }
	.what-project-div {
       padding-bottom: 20px;
     }
	.comon-text-sec h5, .comon-text-sec p {
        padding-left: 0;
        margin-top: 10px;
      }
    .comon-text-sec a {
        margin-left: 0;
      }
	.year-text h1 {
        background-size: 100%;
     }
	.sec-d7 .read-more-bn {
         margin: auto;
        text-align: center;
        display: table;
    }
	.comon-text-sec figure{
		height: 430px;
	}
	footer .row > div:nth-child(2) {
       justify-content: left;
    }
	footer .row > div:nth-child(3) .comon-footer,
	footer .row > div:nth-child(4) .comon-footer{
      justify-content: left;
    }
	.socal {
        justify-content: start;
    }
	.sec-d1 h1{
		margin-top:30px;
	}
	.banner-div-content h1{
		font-size:38px;
	}
	.top-bar-sec {
      text-align: center;
    }
	.what-project-div figure{
		height:540px;
	}
	.navbar{
		flex-wrap: nowrap;
	}
	.com-link:nth-child(1){
		display: none;
	}
	.cart-new-icon{
		display: block !important;
	}
	.consult-bn{
		margin: auto;
	}
	.banner-part{
		top:80px;
	}
	.banner-div-content{
		text-align: center;
		display: grid;
		align-content: center;
	}
	.banner-div-content h6{
		font-size: 22px;
	}
	.banner-div-content p{
		font-size: 13px;
	}
	.produc-div{
		margin-bottom: 20px;
	}
	.comon-seed{
	   margin-bottom: 30px;
       display: inline-block;
	}
	.offer-div-sec{
		height: auto;
		padding: 30px 0;
	}
	/* .comon-our-text{
		margin-bottom: 30px;
	} */

	.silder-div-text .owl-next {
		right: 37px;
	
	}
	.silder-div-text .owl-nav .owl-prev {
		left: 0px;
		
	}
	
}
@media (max-width: 768px){
	.conatct-form-div h2, .cm-text-n h2 {
		font-size: 23px;
	}
	.search-bn {
		width: 100%;
		margin-top: 15px;
	}
	.banner-part {
		top: 80px;
		height: 460px;
	}
	.bg-img2{
		bottom: -140px;
	}
	.banner-part{
		align-content: inherit;
	}
	.about-div-pic {
       margin-top: 50px;
    }
	.comon-st-div {
      display: block;
      text-align: center;
    }
	.list-work ul {
       flex-direction: column;
    }
	.list-work ul li {
          margin-bottom: 15px;
    }
	.what-project-div {
       padding-bottom: 20px;
     }
	.comon-text-sec h5, .comon-text-sec p {
        padding-left: 0;
        margin-top: 10px;
      }
    .comon-text-sec a {
        margin-left: 0;
      }
	.year-text h1 {
        background-size: 100%;
     }
	.sec-d7 .read-more-bn {
         margin: auto;
        text-align: center;
        display: table;
    }
	.comon-text-sec figure{
		height: 430px;
	}
	footer .row > div:nth-child(2) {
       justify-content: left;
    }
	footer .row > div:nth-child(3) .comon-footer,
	footer .row > div:nth-child(4) .comon-footer{
      justify-content: left;
    }
	.socal {
        justify-content: start;
    }
	.sec-d1 h1{
		margin-top:30px;
	}
	.banner-div-content h1{
		font-size:38px;
	}
	.top-bar-sec {
      text-align: center;
    }
	.what-project-div figure{
		height:540px;
	}
	.listed-bn{
		/* margin-top: 15px; */
	}
	
}
@media (max-width: 668px){
	.search-bn {
		width: 100%;
		margin-top: 15px;
	}
	.comon-news-part{
		height: 460px;
		display: inline-block;
	}
	.blog-post ul li {
		margin: 0 7px;
	}
	
	.form-wizard-next-btn{
		margin-bottom: 20px;
	}
	.comon-pic-news,
	.dtails-idv-text{
		width: 100%;
	}
	.dtails-idv-text{
		padding: 10px;
	}
	.read-more-bn {
		bottom: -30px;
	}
	.subpage-banner{
		height: 230px !important;
		overflow: hidden !important;
	}
	.subpage-banner .banner-div-content{
		height: 88px;
	}
	.view-products{
		font-size: 13px;
		padding: 10px 6px;
	}
	.about-div-pic {
       margin-top: 50px;
    }
	.comon-st-div {
      display: block;
      text-align: center;
    }
	.list-work ul {
       flex-direction: column;
    }
	.list-work ul li {
          margin-bottom: 15px;
    }
	.what-project-div {
       padding-bottom: 20px;
     }
	.comon-text-sec h5, .comon-text-sec p {
        padding-left: 0;
        margin-top: 10px;
      }
    .comon-text-sec a {
        margin-left: 0;
      }
	.year-text h1 {
        background-size: 100%;
     }
	.sec-d7 .read-more-bn {
         margin: auto;
        text-align: center;
        display: table;
    }
	footer .row > div:nth-child(2) {
       justify-content: left;
    }
	footer .row > div:nth-child(3) .comon-footer,
	footer .row > div:nth-child(4) .comon-footer{
      justify-content: left;
    }
	.socal {
        justify-content: start;
    }
	.sec-d1 h1{
		margin-top:30px;
	}
	.banner-div-content h1{
		font-size:38px;
	}

	/* new home */

	.verify-modal h2{
		font-size: 22px;
		line-height: 33px;
	}
	header {
		height: auto;
		top:0;
		background: #fff;
	}
	.mn-head .sc-logo{
		display: block;
	}
	.com-link{
		color: #000;
	}
	.banner-div-content{
		height: 248px;
	}
	.home-about{
		margin-top: 60px;
		margin-bottom: 40px;
	}
	.td-menu li{
		margin-bottom: 16px;
	}
	.home-about h2, .Prodcuts-div h2, .choose-bread h2{
		font-size: 27px;
	}
	footer .footer-bottom-div > div:nth-child(2) .comon-footer{
		justify-content: start;
	}
	
}
@media (max-width: 480px){
	h1{
		font-size:20px;
	}
	p{
		font-size:14px;
	}
	.banner-part .carousel-caption h1, .banner-part .carousel-caption p{
		text-align:center;
	}
	header{
		height:auto;
		}
	.top-head{
		position:absolute;
	}
	.top-bar-sec{
		text-align:center;
	}
	.top-bar-sec .row > div:nth-child(2){
		justify-content: center;
	}
	.fixed-menu{
		/*top:40px;*/
		/* padding-top: 20px; */
	}
	.search-bn{
		width:100%;
		margin-top:15px;
	}
	.banner-div-content h1{
		font-size:29px;
	}
	.banner-div-content h1 span{
		font-size: 35px;
	}
	.about-div-pic{
		margin-top:50px;
	}
	.comon-st-div{
	   display: block;
       text-align: center;
	}
	.list-work ul {
		flex-direction: column;
	}
	.list-work ul li{
		margin-bottom:15px;
	}
	.what-project-div{
		padding-bottom:20px;
	}
	.comon-text-sec h5, .comon-text-sec p{
		padding-left:0;
		margin-top:10px;
	}
	.comon-text-sec a{
		margin-left:0;
	}
	.sec-d-expart .row {
      width: 100%;
	}
	.year-text h1{
		background-size: 100%;
	}
	.sec-d7 .read-more-bn{
		margin: auto;
		text-align: center;
		display: table;
	}
	footer .row > div:nth-child(2) {
       justify-content: left;
    }
	.socal {
       justify-content: start;
     }
	.banner-part .carousel-inner > div:nth-child(1) .order-now, .banner-part .carousel-inner > div:nth-child(3) .order-now,
	.banner-part .carousel-inner > div:nth-child(2) h1,
	.banner-part .carousel-inner > div:nth-child(3) h1,
	.banner-part .carousel-inner > div:nth-child(2) .order-now,
	.banner-part .carousel-inner > div:nth-child(3) .order-now{
		text-align:center;
		font-size: 10px;
		    margin: auto;
	}
	.banner-part{
		height: 340px;
		padding:10px 0;
		overflow:inherit;
		align-content: inherit;
	}
	.carousel-caption{
		margin:10px 0;
		display:inline-block;
		}
	.carousel-control-prev,
	.carousel-control-next{
		display:none;
	}
	.how-it-work{
		background:none;
	}
	#fil-group-bn,
	.cost-bn{
		text-align: center;
        display: table;
        margin: auto;
		
	}
	.sec-d4 .card{
		width:100%;
	}
	.sec-d7 .restarn-link{
		    columns:1;
          -webkit-columns: 1;
		      text-align: center;
	}
	.banner-part .carousel-inner > div:nth-child(2) .order-now{
		float:none;
	}
	.cart {
		margin-left:0;
	}
	footer .row > div:nth-child(3) .comon-footer,
	footer .row > div:nth-child(4) .comon-footer,
	footer .row > div:nth-child(5) .comon-footer{
		    justify-content: flex-start;
	}
	.sec-d7  .w-50{
		width:100%!important;
	}
	.sec-d8 figure{
		display:none;
	}
	.sec-d8{
		padding-top:15px;
		height:254px;
	}
	.text-apps{
		text-align:center;
	}
}


/* news res */
@media (max-width: 1180px){
	.main-menu .nav-link{
         font-size: 14px;
	}
	.comon-op{
		height: 164px;
	}
	.toipc-op a{
		font-size: 14px;
	}
}
@media (max-width: 900px){
	header{
		background: #222 !important;
	}
	.toipc-op a{
		font-size: 16px;
	}
	.goole-ads1{
		justify-content: center;
	}
	.comon-op{
		height: auto;
		margin-bottom: 30px;
	}
}
@media (max-width: 667px){
	.share-divs li a{
		width: 50%;
		margin-bottom: 10px;
	}
}
@media (max-width: 480px){
.comon-news-cat figure{
	width: 100%;
	margin-bottom: 10px !important;
}
.news-content-sdiv{
	width: 100%;
	padding-left: 0;
}
}
.video-modal-1 .big-cv{
	height: auto;
}
#videoModal .modal-title{
	visibility: hidden;
}
.navbar-toggler{
	color: #fff;
	border: none;
}
.navbar-toggler:focus{
	box-shadow: none;
}
.navbar-toggler i{
	color: #fff;
}
.suscribe-sec-ft .form-control{
	border-radius: 0;
}
.mobile-menu-sec .active-m a {
    background: #d42a23;
    color: #fff;
}
.mobile-menu-sec li a, .mobile-menu-sec li .dropdown .btn,
.mobile-menu-sec .btn-group{
    text-decoration: none;
    color: #212529;
    padding: 10px 5px 10px 10px;
    display: block;
    background: #f7f7f7;
    margin-bottom: 3px;
    width: 100%;
    text-align: left;
}
button.close-menu{
	color: #fff !important;
}
#search-div-id{
	display: none;
	width: 100%;
}
#menu-id{
	display: inline-block;
	width: 100%;
}
#Show-search{
	cursor: pointer;
}
#search-div-id .form-control{
	background: none;
	border: none;
	box-shadow: none;
	border-bottom: solid 1px #222;
	border-radius: 0;
	color: #fff;
}
#search-div-id .form-control::placeholder{
	color: #fff !important;
}
.search-n{
	cursor: pointer;
	color: #fff;
}

.breaking-news-divs .letf h5{
	font-size: 16px;
}

.banner_info_line{
	width: 100%;
	height: 126px;
}

.banner_info_line > .dashboard-list-item_main{
	width: 100%!important;
	max-width: 100%!important;
}




