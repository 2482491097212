.myModal {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: none;
    background: rgba(0,0,0, 0.5);
    color: #fff;
    font-weight: bold;
    font-size: 20px;
    z-index: 100;
}

.myModalContent {
    padding: 25px;
    text-align: center;
    background: rgb(239 68 68);
    box-shadow: 0 8px 25px -8px #ea5455;
    border-radius: 16px;
    min-width: 250px;
}

.myModal.active {
    display: flex;
    justify-content: center;
    align-items: center;
}