.article_box{
    display: flex;
    justify-content: center;
    padding: 0 2rem;
}
.loader_box {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader {
    width: 60px;
    aspect-ratio: 2;
    --_g: no-repeat radial-gradient(circle closest-side,#000 90%,#0000);
    background:
            var(--_g) 0%   50%,
            var(--_g) 50%  50%,
            var(--_g) 100% 50%;
    background-size: calc(100%/3) 50%;
    animation: l3 1s infinite linear;
}

@keyframes l3 {
    20%{background-position:0%   0%, 50%  50%,100%  50%}
    40%{background-position:0% 100%, 50%   0%,100%  50%}
    60%{background-position:0%  50%, 50% 100%,100%   0%}
    80%{background-position:0%  50%, 50%  50%,100% 100%}
}

.article_body{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 750px;
    min-width: 630px;
    padding: 2rem 6rem 2rem 0;
}
.article_more_btn{
    border: none;
    padding: 0.5rem 1rem;
    background-color: #eb3b5a;
    color: #fff;
    border-radius: 12px;
    cursor: pointer;
    margin-top: 1rem;
}

.article_body h2{
    width: 100%;
    display: block;
}
.article_body h2 > strong, .article_body h2 > a{
    font-size: 1.9rem!important;
    text-align: left;
    width: 100% !important;
}
.article_body p{
    width: 100%;
    text-align: left;
    font-size: 1.35rem;
}
.article_body  p > a, .article_body  h2 > a, .article_body  h3 > a, .article_body  li > a{
    text-decoration: underline;
    color: #eb3b5a;
}

.article_body ol > li{
    width: 100%;
    text-align: left;
    font-size: 1.2rem;
}

.article_body h1 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.article_body h1 > a {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eb3b5a;
    color: #fff;
    border-radius: 12px;
    padding: 1rem;
    font-size: 1.4rem;
}

.article_body  p > a > img{
    width: 100%;
}
.article_body img{
    max-width: 650px;
    width: 100%;
}

.article_banner_box{
    display: flex;
    width: 100%;
}

.banners_box{
    /*background-color: #5d5d5d;*/
    /*max-width: 250px;*/
    width: 320px;
    margin: 2rem 0 0 0;
    height: fit-content;
    /*border-left: 1px solid #eb3b5a;*/
}

.article_side_banner{
    display: flex;
    margin: 0 0 10px 0;
    border: solid 1px rgba(25,25,25,0.1) !important;
    border-radius: 6px;
}
.article_side_banner-img{
    width: 33.333%;
    height: auto;
    /*height: fit-content;*/
    margin-right: 10px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}
.article_side_banner-title{
    font-size: 16px;
    text-decoration: none;
    margin: 0;
    color: #010101;
    font-weight: 500;
    transition: 150ms;
    line-height: 130%;
    padding: 4px 2px;
}

/*.article_side_banner{*/
/*    width: 100%;*/
/*    height: 310px !important;*/
/*    min-height: 312px;*/
/*    position: relative;*/
/*}*/
/*.article_side_banner-img{*/
/*    position: absolute;*/
/*    top: 0;*/
/*    bottom: 0;*/
/*    right: 0;*/
/*    left: 0;*/
/*    width: 320px;*/
/*    height: 310px;*/
/*    border-bottom-right-radius: 6px;*/
/*    border-bottom-left-radius: 6px;*/
/*}*/
/*.article_side_banner-title{*/
/*    position: absolute;*/
/*    bottom: 0;*/
/*    right: 0;*/
/*    left: 0;*/
/*    padding: 20px 4px 6px;*/
/*    background: rgb(0,0,0);*/
/*    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.5676864495798319) 69%, rgba(255,255,255,0) 100%);*/
/*    color: #f1f1f1;*/
/*    border-bottom-right-radius: 6px;*/
/*    border-bottom-left-radius: 6px;*/
/*}*/

@media all and (max-width: 480px){
    .article_box{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 1rem;
    }
    .article_body{
        max-width: 385px;
        min-width: 325px;
        width: 100%;
        padding: 0;
    }

    .article_body p > img{
        width: 100%;
    }
    .banners_box{
        max-width: 100%;
        width: 100%;
        padding: 1.4rem 0.4rem;
        border-top: 1px solid rgba(25,25,25,0.7);
    }
    /*.article_side_banner{*/
    /*    width: 100%;*/
    /*    height: 310px !important;*/
    /*    min-height: 312px;*/
    /*    position: relative;*/
    /*}*/
    /*.article_side_banner-img{*/
    /*    position: absolute;*/
    /*    top: 0;*/
    /*    bottom: 0;*/
    /*    right: 0;*/
    /*    left: 0;*/
    /*    width: 100%;*/
    /*    height: 310px;*/
    /*    border-bottom-right-radius: 6px;*/
    /*    border-bottom-left-radius: 6px;*/
    /*}*/
    /*.article_side_banner-title{*/
    /*    position: absolute;*/
    /*    bottom: 0;*/
    /*    right: 0;*/
    /*    left: 0;*/
    /*    padding: 20px 4px 6px;*/
    /*    background: rgb(0,0,0);*/
    /*    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.5676864495798319) 69%, rgba(255,255,255,0) 100%);*/
    /*    color: #f1f1f1;*/
    /*    border-bottom-right-radius: 6px;*/
    /*    border-bottom-left-radius: 6px;*/
    /*}*/

    .article_side_banner{
        min-height: 90px;
        height: auto;
        flex-direction: column;
        max-height: none;

    }
    .article_side_banner-img{
        width: 100%;
        object-fit: contain;
        border-bottom: 1px solid rgba(25,25,25,0.2);
        /*max-height: 146px;*/
        border-radius: 6px 6px 0 0;
    }
    .article_side_banner-title {
        font-size: 1.1rem;
        padding: 10px;

    }
    .banners_box .col{
        box-shadow: rgb(0 0 0 / 4%) 3px 4px 0px;
        margin-bottom: 20px;
    }
    .comon-slider-items figure{
        height: 200px;
    }

    /* .article_banner_box a{
        flex-direction: column;
    } */
    .small-life-divs{
        padding: 0;
    }
    .small-life-divs figure {
        width: 100%;
        height: auto;
        display: inline-block;
        overflow: hidden;
        margin: 0;
        margin-right: 10px;
        /* max-width: 100%; */
        max-width: 30%;
        /* aspect-ratio: 1/1; */
    }
    .small-life-divs figure > p{
        min-height: 90px;
        
    }
    .small-life-divs figure img{
        min-height: 90px;
    }
    .small-life-divs .right-test2{
        padding: 2px 6px 0 2px;
        width: 100%;
    }
    .article_banner_box_info_title{
        font-size: 12px !important;
    }
    .article_banner_box_info_description p{
        font-size: 12px!important;
        /* color: rgba(25, 25, 25, 0.9)!important; */
    }
}