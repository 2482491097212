
.about_main-title{
    font-size: 18px;
    color: rgba(25,25,25,0.85);
    font-weight: bold;
    text-align: center;
    line-height: 1.5;
    padding: 10px;
}

.about-info-item-title{
    padding: 10px;
    width: 100%;
    height: 80px;
    border: 1px solid #3e3e3e;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.about-info-item-text{
    padding: 10px;
    width: 99%;
    border: 1px solid #3e3e3e;
    border-radius: 10px;
    background-color: #f1f1f1;
    overflow-y: scroll;
    font-size: 20px!important;

    position: fixed;
    top: 10px;
    right: 1px;
    left: 1px;
    bottom: 100px;
}

.about-info-close-modal {
    position: fixed;
    top: auto;
    right: 1px;
    left: 1px;
    bottom: 30px;

    padding: 10px;
    width: 99%;
    border: 1px solid #3e3e3e;
    border-radius: 10px;
    text-transform: uppercase;
    text-align: center;
    background-color: #eb3b5a;
    font-weight: bold;
    color: #fff;
}